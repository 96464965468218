import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo_classes from "./UI/logo/Logo.module.css";
import imgLogo from "./UI/logo/img/UntUitLogo.png";
import LoginIcon from '@mui/icons-material/Login';
import {ButtonGroup} from "@mui/material";
import SpeedDialTooltipOpen from "./menuList";

const pages = {'Root': '/', 'Publish': "/Submit", 'Archives': "/Archives", 'Docs': "/Docs"};


function ResponsiveAppBar({firstName, lastName, logout}) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="sticky" sx={{bgcolor: 'rgba(230, 230, 230, 0.98)'}}>
            <Container maxWidth="xl">

                <Toolbar disableGutters sx={{justifyContent: "space-between"}}>
                    {/*<img className={logo_classes.imgLogo} sx={{display: {xs: 'flex', md: 'none'}, mr: 1}} src={imgLogo} alt="NaN"></img>*/}

                    <Box
                        component="img"
                        sx={{display: {xs: 'none', md: 'flex'}, mr: 2}}
                        alt="The house from the offer."
                        src={imgLogo}
                        className={logo_classes.imgLogo}
                    />
                    <Typography
                        className={logo_classes.headerLogo}
                        variant="subtitle1"
                        noWrap
                        component="a"
                        href="/"
                        sx={{flexGrow: 3, display: {xs: 'none', md: 'flex'}, mr: 2}}
                    >
                        SCIENCE AND INNOVATIVE TECHNOLOGIES
                    </Typography>

                    <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="gray"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {Object.entries(pages).map(([page, link]) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"
                                                component='a'
                                                href={`${link}`}
                                                sx={{
                                                    color: 'inherit',
                                                    textDecoration: 'none',
                                                }}
                                    >{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        component="img"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        alt="The house from the offer."
                        src={imgLogo}
                        className={logo_classes.imgLogo}
                    />
                    <Box sx={{flexGrow: 0.1, display: {xs: 'none', md: 'flex'}}}>
                        {Object.entries(pages).map(([page, link]) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                component='a'
                                href={`${link}`}
                                sx={{my: 2, color: '#2a2073', display: 'block', fontStyle: "normal"}}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {firstName && lastName ? (
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt="Remy Sharp" src=""/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >

                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Typography
                                        style={{textDecoration: 'none'}}
                                        textAlign="center" component='a' href='/Profile'
                                        fontStyle='normal'
                                        variant='button'>Profile</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Button onClick={logout}>
                                        <Typography
                                            style={{textDecoration: 'none'}}
                                            textAlign="center"s
                                            fontStyle='normal'
                                            variant='button'>Logout</Typography></Button>
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <Box>
                            <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                                    <ButtonGroup>
                                        <Button variant='outlined' sx={{fontSize: 12, fontStyle: 'normal'}} component='a' href='/Auth'>
                                            Log In
                                        </Button>
                                        <Button variant='outlined' sx={{fontSize: 12, fontStyle: 'normal'}} component='a' href='/Register'>
                                            Sign Up
                                        </Button>
                                    </ButtonGroup>

                            </Box>
                            <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                                <Tooltip title="Open settings">
                                    <IconButton sx={{p: 0}} onClick={handleOpenUserMenu}>
                                        <LoginIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}

                                >

                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center" component='a' href='/Auth'
                                                    sx={{textDecoration: 'none', color: 'inherit'}}
                                                    fontStyle="normal">Log In</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center" component='a' href='/Register'
                                                    sx={{textDecoration: 'none', color: 'inherit'}}
                                                    fontStyle="normal">Sign Up</Typography>
                                    </MenuItem>

                                </Menu>
                            </Box>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;