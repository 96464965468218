//React
import React, {useState} from 'react';

//Packages
import axios from 'axios';

//Local
import classes from './AuthStyle.module.css'
import {saveDataToSessionStorage} from "./Utils/saveDataToSessionStorage";

//Config
import config from "../../Config";
import {Alert, Box, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function AuthorizationForm({handleLogIn, setAlertMessage, handleAlertToggle}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [openError, setOpenError] = useState(false);
    const [alertMessageError, setAlertMessageError] = useState("Возникла ошибка. Проверьте правильность введенных данных и попробуйте еще раз")

    const handleSubmit = (event) => {
        event.preventDefault();

        const userData = {
            email: email,
            password: password
        };

        axios.post(config.apiUrl + '/users/login/', userData)
            .then(response => {
                saveDataToSessionStorage(response.data)
                setAlertMessage("Вы успешно авторизовались на сайте, ознакомьтесь с главной страницей и документацией перед тем как публиковать статьи")
                handleAlertToggle()
                handleLogIn();
            })
            .catch(error => {
                // Обработка ошибки
                console.error(error);
                setOpenError(true)
            });
    };

    return (
        <div>
            <>
                <Box sx={{width: '100%'}}>
                    <Collapse in={openError}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenError(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                            sx={{mb: 2}}
                        >
                            {alertMessageError}
                        </Alert>
                    </Collapse>
                </Box>
            </>

            <div className={classes.container}>
                <form className={classes.form_auth} onSubmit={handleSubmit}>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Email' type="email" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                    </label>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Password' type="password" value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                    </label>
                    <button className={classes.button_auth} type="submit">Log In</button>
                </form>
            </div>
        </div>
    );
}

export default AuthorizationForm;
