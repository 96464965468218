import React from "react";
import {AccordionSummary} from "@mui/material";
import post_style from "../OnPublishList/OnPublish.module.css";

const ArchiveLink = (props) => {
    return (
        <div className={post_style.post}>
            <a href={props.href} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                <AccordionSummary>
                    <div className={post_style.post__title}>

                        {props.strong.charAt(0).toUpperCase() + props.strong.toLowerCase().slice(1)} {" - "}
                        <non style={{color: "#0051b5"}}>{props.em + " " + props.other}</non>
                    </div>
                </AccordionSummary>
            </a>
        </div>
    );
};

export default ArchiveLink;