import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function Review({post}) {
    return (
        <React.Fragment>
            <Typography variant="h5" gutterBottom>
                Title summary
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">Post title:</Typography>
                    <Typography gutterBottom>on Kyrgyz: {post.title_kg}</Typography>
                    <Typography gutterBottom>on English: {post.title_en}</Typography>
                    <Typography gutterBottom>on Russian: {post.title_ru}</Typography>

                    <Typography gutterBottom variant="h6">Post annotation & keywords:</Typography>
                    <Typography gutterBottom>on Kyrgyz: {post.annotation_kg}</Typography>
                    <Typography gutterBottom>on English: {post.annotation_en}</Typography>
                    <Typography gutterBottom>on Russian: {post.annotation_ru}</Typography>

                    <Typography gutterBottom variant="h6">Post Author:</Typography>
                    <Typography gutterBottom>Name: {post.title_author_en}</Typography>
                    <Typography gutterBottom>Position: {post.author_organization_en}</Typography>
                    <Typography gutterBottom>Mail: {post.contact_email}</Typography>


                </Grid>
            </Grid>
        </React.Fragment>
    );
}