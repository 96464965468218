import React, {useEffect, useState} from 'react';
import post_style from "../OnPublishList/OnPublish.module.css";
import {Accordion, AccordionDetails, AccordionSummary, Box, ButtonGroup, Divider, Rating} from '@mui/material';

import {splitAndFilterValues} from "../Utils/splitUtils";
import Typography from "@mui/material/Typography";

import FormDialog from "./Dialog";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import axios from "axios";
import config from "../../../Config";


const ArchiveItems = (props) => {
    const numbers = ["⁽¹⁾", "⁽²⁾", "⁽³⁾", "⁽⁴⁾", "⁽⁵⁾", "⁽⁶⁾", "⁽⁶⁾", "⁽⁸⁾", "⁽⁹⁾", "⁽¹⁰⁾"];

    const co_authors_split = splitAndFilterValues(props.post.co_authors_en);
    const co_authors_organization_split = splitAndFilterValues(props.post.co_authors_organization_en);
    const co_authors_emails_split = splitAndFilterValues(props.post.co_authors_emails);

    const [expanded, setExpanded] = useState(false);

    const [reviews, setReviews] = useState([]);

    const id = props.post.id

    const handleAccordionChange = () => {
        return setExpanded(!expanded);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(config.apiUrl + `/posts/${id}/comment/`)
            setReviews(response.data)
            console.log(response.data)
        }
        fetchData();
    }, [])


    return (
        <Accordion
            className={`${post_style.post} ${expanded ? post_style.post_expanded : ''}`}
            onChange={handleAccordionChange}>
            <AccordionSummary>
                <Box className={`${post_style.post__title} ${expanded ? post_style.post__title_expanded : ''}`}>

                    <Typography textAlign={expanded ? 'center' : ""} variant='inherit'>
                        {props.post.title_en.charAt(0).toUpperCase() + props.post.title_en.toLowerCase().slice(1)} {expanded ? '' : " - " + props.post.title_author_en}
                    </Typography>

                </Box>
            </AccordionSummary>
            <AccordionDetails className={post_style['post__content']}>
                <Box className={post_style.post__author}>

                    <Typography style={{"fontStyle": "italic", display: 'flex', flexWrap: 'wrap'}} variant='inherit'>
                        {numbers[0]} {props.post.author_organization_en},
                        <Typography style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} variant='inherit' component='a'
                                    href={`mailto:${props.post.contact_email}`}>{props.post.contact_email}
                        </Typography>

                    </Typography>
                    {co_authors_organization_split.map((co, index) => (
                        <Typography key={index} style={{"fontStyle": "italic"}} variant='inherit'>
                            {numbers[index + 1]} {co},
                            <Typography variant='inherit' component='a'
                                        href={`mailto:${co_authors_emails_split[index]}`}>{co_authors_emails_split[index]}
                            </Typography>
                        </Typography>
                    ))}
                </Box>
                <Box className={post_style.post__annotation}>
                    <span style={{
                        "fontWeight": "600",
                        "fontStyle": "italic",
                        "paddingLeft": "40px"
                    }}>Abstract:
                    </span>
                    {" " + props.post.annotation_ru}
                </Box>
                <Box className={post_style.post__keywords}>
                    <span variant='inherit'
                          style={{"fontWeight": "600", "fontStyle": "italic", "paddingLeft": "40px"}}>Keywords:
                    </span>
                    {" " + props.post.keywords_ru}
                </Box>

                <Box className={post_style.post__keywords}
                     style={{marginTop: "10px", display: props.post.file ? "block" : "none",}}>
                </Box>


                <Box display="flex" justifyContent="right" marginBottom={3}>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button component='a' href={props.post.file} size='small' variant='outlined'>
                            Open
                        </Button>
                        <FormDialog navigate={props.navigate} id={props.post.id}/>
                    </ButtonGroup>

                </Box>


                {reviews.length > 0 ? (
                    <Box>
                        <Divider>Reviews</Divider>
                        <Paper style={{maxHeight: 200, overflow: 'auto', minHeight: 200, marginTop: 15}}>
                            {reviews.map((review) => (
                                <Box>
                                    <Divider/>
                                    <Typography marginLeft={1}>{review.author}: {review.review}</Typography>
                                    <Rating value={review.rating} precision={0.5} name="read-only" readOnly/>
                                    <Divider/>

                                </Box>
                            ))}

                        </Paper>
                    </Box>
                ) : (
                    <></>
                )}


            </AccordionDetails>
        </Accordion>
    );
};

export default ArchiveItems;
