import {Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from "./Accordion.module.css"
import Typography from "@mui/material/Typography";

const CustomAccordion = ({title, content, mode}) => {
    return (

        <Accordion style={{backgroundColor: '#f0f0f0'}} variant='outlined' className={classes.accordion}
                   TransitionProps={{unmountOnExit: true}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} className={classes.accordion_header}>
                <Typography color="#0051b5">{title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Box>{content}</Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;