import React from "react";
import "./DocsStyle.css"

const DocsPage = () => {
    return (
        <div className="blockText_docs" style={{maxWidth: 1000}}>
            <p className="text_docs">
                <h2 className="h2_docs"> Оформление научной статьи </h2><br/>
                <p>Текст статьи должен быть напечатан на одной стороне листа белой бумаги формата А4 (210×297 мм) на
                    струйном или лазерном принтере через 1 строчный интервал, расстановка переносов –
                    автоматическая.</p>
                <br/>
                <p>При подготовке текста доклада используйте шрифт Times New Roman, формат бумаги А4 (210х297 мм) с
                    полями: верхнее и нижнее – 25 мм, левое и правое – 25 мм. (Рабочее поле 160×247 мм). Абзацный отступ
                    – 10 мм.</p>
                <br/>
                <p>В качестве редактора следует использовать MS Word 2007 (и более поздние версии).</p>
                <br/>
                <p>Размеры шрифта: </p>
                <ul className="ul_docs">
                    <li className="li_docs">ЗАГОЛОВОК печатается прописными буквами, полужирным шрифтом – 14 пт без
                        переносов
                    </li>
                    <li className="li_docs">Основной текст и Ключевые слова – 12 пт</li>
                    <li className="li_docs">Аннотация – 10 пт</li>
                    <li className="li_docs">Формулы – 12 пт. Формулы размещаются по центру текста, номера формул должны
                        быть прижаты к правому краю текста.
                    </li>
                </ul>
                <br/>
                <p>Таблицы и рисунки должны быть встроены в текст статьи, использование ксерокопий не допускается.
                    Таблицы и рисунки должны иметь название и номер. Подпись к таблице печатается над таблицей, а
                    подпись к рисунку – под ним.</p>
                <br/>
                <p>Ссылки на литературу нумеруются последовательно, в порядке их цитирования, и заключаются в квадратные
                    скобки. Фамилии авторов, ученая степень, место работы авторов и Литература печатаются курсивом,
                    размер шрифта – 12 пт.</p>
                <br/>
                <p>Последняя страница статьи должна быть заполнена текстом не менее чем на 75%.</p>

                <br/><br/>
                <p>Структурные элементы статьи:</p>
                <ol>
                    <li className="li_docs">
                        <p>Название доклада (на кыргызском, русском и английском языках).</p>
                    </li>
                    <li className="li_docs">
                        <p>Сведения об авторах (на кыргызском, русском и английском языках):</p>
                        <ul className="ul_docs">
                            <li className="li_docs">Фамилия, имя, отчество автора (авторов) полностью;</li>
                            <li className="li_docs">Полное название организации – место работы каждого автора в
                                именительном падеже, страна, город. Если все авторы статьи работают в одном учреждении,
                                можно не указывать место работы каждого автора отдельно;
                            </li>
                            <li className="li_docs">Контактная информация (e-mail) для каждого автора.</li>
                        </ul>
                    </li>
                    <li className="li_docs">
                        <p>Аннотация (на кыргызском, русском и английском языках) (100-250 слов).</p>
                    </li>
                    <li className="li_docs">
                        <p>Ключевые слова (на кыргызском, русском и английском языках). Ключевые слова или
                            словосочетания отделяются друг от друга точкой с запятой (5- 10 слов).</p>
                    </li>
                    <li className="li_docs">
                        <p>Тематический рубрикатор: УДК/ББК либо другие библиотечно-библиографические классификационные
                            и предметные индексы.</p>
                    </li>
                    <li className="li_docs">
                        <p>Текст доклада (междустрочный интервал 1,5):</p>
                        <ul className="ul_docs">
                            <li className="li_docs">введение,</li>
                            <li className="li_docs">формулировка проблемы или задачи,</li>
                            <li className="li_docs">предлагаемое вами решение и его теоретическое обоснование,</li>
                            <li className="li_docs">практическое исследование и выводы,</li>
                            <li className="li_docs">заключение.</li>
                            <li className="li_docs">Обязательные структурные элементы выделены полужирным шрифтом.
                                Остальные структурные элементы включаются в статью по усмотрению автора. Объём статьи
                                должен быть не менее 5 и не более 15 страниц.
                            </li>

                        </ul>
                    </li>
                    <li className="li_docs">
                        <p>Библиографический список литературы (только на языке оригинала).</p>
                    </li>
                    <p>К материалам статьи необходимо приложить Акт экспертизы и/или рецензию от специалиста с ученой
                        степенью, работающего в данной отрасли науки.</p>
                    <p>Авторы статей несут ответственность за оригинальность, объективность и обоснованность публикуемых
                        материалов. Обязательна проверка статей на обнаружение заимствованного материала.</p>
                    <br/>
                    <p>Для контроля уровня оригинальности, рекомендуется:</p>
                    <ul className="ul_docs">
                        <li className="li_docs">предоставлять рекомендацию внешнего рецензента;</li>
                        <li className="li_docs">предоставлять редакции распечатку «Антиплагиат»;</li>
                        <li className="li_docs">допустимый уровень заимствований не должен превышать 12% (оригинальность
                            не должна быть менее 88%);
                        </li>
                        <li className="li_docs">минимальное количество цитирований для обзорных статей – 20 источников,
                            для разработок – 10 источников;
                        </li>
                        <li className="li_docs">в списке литературы настоятельно рекомендуются ссылки на номера DOI
                            источника.
                        </li>
                    </ul>


                    <br/>
                    <br/>
                    <p>Стоимость публикации:</p>
                    <ul className="ul_docs">
                        <li className="li_docs">до 8-10 страниц – 1000 сом;</li>
                        <li className="li_docs">за каждую следующую страницу доплата 150 сом.</li>
                    </ul>
                    <br/>
                    <ol>
                        <li className="li_docs">Статьи, поступившие в редакцию журнала «Наука и инновационные
                            технологии», проходят обязательное внешнее рецензирование.
                        </li>
                        <li className="li_docs">Автор направляет в редакцию журнала « Наука и инновационные технологии »
                            статью в соответствии с информацией для авторов «Требования к оформлению рукописей статей»,
                            опубликованной на сайте.
                        </li>
                        <li className="li_docs">Статье, поступившей в редакцию, присваивается регистрационный номер.
                        </li>
                        <li className="li_docs">Ответственный секретарь в течение 3 дней уведомляет авторов о получении
                            статьи.
                        </li>
                        <li className="li_docs">Ответственный секретарь определяет соответствие статьи профилю журнала,
                            требованиям к оформлению. Материалы, не соответствующие этим условиям, возвращаются авторам
                            с указанием причин отказа в приеме рукописей. Материалы, удовлетворяющие этим условиям,
                            направляются по предметному профилю на рецензию одному из членов редколлегии журнала « Наука
                            и инновационные технологии».
                        </li>
                        <li className="li_docs">
                            Не рецензируются:
                            <ul className="ul_docs">
                                <li className="li_docs">статьи академиков и членов-корреспондентов Национальной Академии
                                    наук КР в том случае, если академик или член-корреспондент — единственный или первый
                                    из авторов публикации;
                                </li>
                                <li className="li_docs">статьи, прошедшие представление на научных, научно-технических,
                                    научнометодических и методических советах научных организаций и высших учебных
                                    заведений, и имеющие письменное заключение Совета с рекомендацией об опубликовании.
                                </li>
                            </ul>
                        </li>
                        <li className="li_docs">К рецензированию не привлекаются специалисты, работающие в том же
                            научно-исследовательском учреждении или высшем учебном заведении, где выполнена работа.
                        </li>
                        <li className="li_docs">Рецензенты уведомляются о том, что присланные им рукописи являются
                            частной собственностью авторов и относятся к сведениям, не подлежащим разглашению.
                        </li>
                        <li className="li_docs">Рецензирование проводится конфиденциально. Автору рецензируемой работы
                            предоставляется возможность ознакомиться с текстом рецензии.
                        </li>
                        <li className="li_docs">Рецензент в течение 14 дней делает заключение о возможности публикации
                            статьи.
                        </li>
                        <li className="li_docs">В рецензии рецензент оценивает:
                            <ul className="ul_docs">
                                <li className="li_docs">соответствие содержания статьи ее названию;</li>
                                <li className="li_docs">новизну рассматриваемой в статье проблемы, актуальность и
                                    практическую значимость;
                                </li>
                                <li className="li_docs">соответствие статьи современным достижениям в рассматриваемой
                                    области науки;
                                </li>
                                <li className="li_docs">форму подачи материала, достоинства и недостатки статьи;</li>
                                <li className="li_docs">целесообразность публикации статьи.</li>
                            </ul>
                        </li>
                        <li className="li_docs">Редакция по электронной почте сообщает автору результаты рецензирования
                            не позднее 14 дней с момента рецензирования.
                        </li>
                        <li className="li_docs">Если в рецензии на статью имеются указания на необходимость ее
                            исправления, то статья направляется автору на доработку.
                        </li>
                        <li className="li_docs">В случае несогласия с мнением рецензента автор статьи имеет право
                            предоставить аргументированный ответ в редакцию журнала. Статья может быть направлена на
                            повторное рецензирование.
                        </li>
                        <li className="li_docs">Окончательное решение о целесообразности публикации после рецензирования
                            принимается главным редактором.
                        </li>
                        <li className="li_docs">В случае положительного заключения главный редактор журнала определяет
                            очередность публикаций в зависимости от тематики номеров журнала.
                        </li>
                        <li className="li_docs">
                            Если публикация статьи повлекла нарушение чьих-либо авторских прав или общепринятых норм
                            научной этики, то редакция журнала вправе изъять опубликованную статью.
                        </li>
                        <li className="li_docs">
                            Редколлегия журнала не хранит рукописи, не принятые к печати. Рукописи, принятые к
                            публикации, не возвращаются. Рукописи, получившие отрицательный результат от рецензента, не
                            публикуются и также не возвращаются обратно автору.
                        </li>
                        <li className="li_docs">
                            Плата с авторов за рецензирование статей не взимается.
                        </li>
                        <li className="li_docs">
                            Редакция не берет на себя обязательства по срокам публикации.
                        </li>
                    </ol>
                </ol>
            </p>
        </div>
    )
};

export default DocsPage;
