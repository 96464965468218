//Local
import classes from './Root.module.css'

//Images
import journal from "./img/journal_cover.jpg"
import ELibrary from "./img/elibrary_logo.png"
import list from "./img/list_of_public_logo.png"
import anti from "./img/anti_plagiarism.png"
import cross from "./img/crossref_logo.svg"
import intuit from "./img/intuit_logo.png"


function Root() {

    return (
        <div className={classes.container}>
            <div className={classes.column_one}>
                <div style={{marginTop: "25px", display: "flex", flexDirection: 'column', alignItems: "center"}}>

                    <img src={ELibrary} alt="Изображение"
                         style={{
                             width: "25vh",
                             maxWidth: "30vh",
                             height: "auto",
                             marginBottom: "10vh"
                         }}/>


                    <img src={list} alt="Изображение"
                         style={{
                             width: "25vh",
                             maxWidth: "30vh",
                             height: "auto",
                             marginBottom: "10vh"
                         }}/>

                    <img src={anti} alt="Изображение"
                         style={{
                             width: "25vh",
                             maxWidth: "30vh",
                             height: "auto",
                             marginBottom: "10vh"
                         }}/>

                    <img src={cross} alt="Изображение"
                         style={{
                             width: "25vh",
                             maxWidth: "30vh",
                             height: "auto",
                             marginBottom: "10vh"
                         }}/>

                    <img src={intuit} alt="Изображение"
                         style={{
                             width: "25vh",
                             maxWidth: "30vh",
                             height: "auto",
                             marginBottom: "10vh"
                         }}/>
                </div>
            </div>

            <div className={classes.column_two}>
                <div className="blockText_docs" style={{width: '90vh', textAlign: 'left'}}>

                    <p className="text_docs">

                        <p>The scientific and informational peer-reviewed journal "Science and Innovative Technologies"
                            (abbreviated as "SIT") has been published since May 2016. It is issued four times a
                            year.</p>
                        <br/>
                        <p>The "Science and Innovative Technologies" journal was established taking into account the
                            modern stage of globalization processes against the backdrop of scientific and technological
                            progress. Articles are published in Kyrgyzstan, Russian, English languages, and there is
                            also the opportunity to publish in Chinese and Arabic.</p>
                        <br/>
                        <p>The journal is released in 2 series:</p>

                        <p style={{margin: 10}}>Natural and technical sciences</p>

                        <p style={{margin: 10}}>Social and humanitarian sciences</p>

                        <br/>
                        <p>The founder of the journal:</p>
                        <br/>
                        <p>International University of Innovative Technologies</p>
                        <br/>
                        <p>The journal is registered in the Ministry of Justice of the Kyrgyzstan Republic (Certificate
                            of Registration №2185 dated May 19, 2016).</p>
                        <br/>
                        <p>ISSN 1694-7762 (Print Edition)</p>
                        <p>ISSN 1694-8114 (Electronic Edition)</p>
                        <p>DOI Prefix — 10.33942</p>
                        <br/>
                        <p>The goals and objectives of the journal are to acquaint the global scientific community with
                            the advanced results of both domestic and foreign research. The journal publishes only
                            original articles that have not been previously published in other periodicals. When working
                            on an article, the editorial board recommends studying the "Article Formatting Requirements"
                            section.</p>

                    </p>

                </div>
            </div>

            <div className={classes.column_three}>
                <img src={journal} alt="Изображение" style={{width: "50vh", marginTop: "25px"}}/>
            </div>


        </div>
    );
}

export default Root;