// React
import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";

// Компоненты
import AddTitle from "./components/Submit/Chekout";
import TitleList from "./components/TitleList/TitleList";
import DocsPage from "./components/Documentation/DocsPage";
import RootPage from "./components/Root/RootPage";
import RegistrationForm from "./components/Authenticate/Registration";
import AuthorizationForm from "./components/Authenticate/Authorization";

// Стили и ресурсы
import "./styles/App.css";


import axios from 'axios';
import {Alert, Box, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import UserPage from "./components/Profile";

import ResponsiveAppBar from "./components/AppBar";

function App() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organization, setOrg] = useState('')
    const [email, setEmail] = useState('')


    const [AToken, setStoredToken] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Мы рады вашему нахождению на сайте. Приятного добавления статей!")

    const handleAlertToggle = () => {
        setOpen(!open)
    };


    useEffect(() => {
        // Получение данных из localStorage или другого источника при монтировании компонента
        document.title = "IntUIT Journal"
        const storedFirstName = localStorage.getItem('firstName');
        const storedLastName = localStorage.getItem('lastName');
        const storedToken = localStorage.getItem('accessToken');

        // Установка начальных значений
        setOrg(localStorage.getItem('userOrganization'))
        setEmail(localStorage.getItem('email'))
        setFirstName(storedFirstName);
        setLastName(storedLastName);
        setStoredToken(storedToken)
    }, []);

    const clearLocalStorage = () => {
        // Очистка значений в localStorage
        localStorage.removeItem('email');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('userOrganization');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');

        setFirstName('');
        setLastName('');
        setStoredToken('');

    };

    const handleLogIn = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken');
            setStoredToken(storedToken)

            navigate("/");
            handleAlertToggle();


            setTimeout(() => {
                window.location.reload()
            }, 5000);

        } catch (error) {
            console.log(error);
        }
    }

    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const accessToken = localStorage.getItem('accessToken');

            // Выполнение запроса на выход пользователя
            await axios.post('https://api.journal-intuit.online/users/logout/', {
                refresh: refreshToken
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            console.log('Logout successful');
            // Очистка localStorage
            clearLocalStorage();
            // Перенаправление пользователя на главную страницу
            window.location.reload();
            navigate("/");

        } catch (error) {
            console.error('Logout error:', error);
            clearLocalStorage();
            // Перенаправление пользователя на главную страницу
            window.location.reload();
            navigate("/");
        }
    };


    return (

        <div className="App">
            <ResponsiveAppBar firstName={firstName} lastName={lastName} logout={handleLogout}/>


            <Box sx={{width: '100%'}} position='fixed'>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleAlertToggle}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        sx={{mb: 2}}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Box>


            <div className="content">

                <Routes>
                    <Route
                        path="/"
                        element={<RootPage/>}
                    />

                    <Route
                        path="/Docs"
                        element={<DocsPage/>}
                    />

                    <Route
                        path="/Archives"
                        element={
                            <TitleList navigate={navigate}/>
                        }
                    />


                    <Route
                        path="/Submit"
                        element={
                            <AddTitle
                                navigate={navigate}
                            />}
                    />

                    <Route
                        path="/Register"
                        element={<RegistrationForm handleLogIn={handleLogIn} setAlertMessage={setAlertMessage}
                                                   handleAlertToggle={handleAlertToggle}/>}
                    />

                    <Route
                        path="/Auth"
                        element={<AuthorizationForm handleLogIn={handleLogIn} setAlertMessage={setAlertMessage}
                                                    handleAlertToggle={handleAlertToggle}/>}

                    />

                    <Route path="/Profile"
                           element={<UserPage navigate={navigate} firstName={firstName} lastName={lastName}
                                              organization={organization} email={email}/>}/>


                </Routes>

            </div>
            <footer className="footer">
                <div className="footer__section">
                    <h3 className="footer__section-title">ADDRESS</h3>
                    <p className="footer__section-text">
                        720048, Kyrgyzstan Republic,<br/>
                        г. Бишкек, ул. Анкара, 1/17
                    </p>
                </div>
                <div className="footer__section">
                    <h3 className="footer__section-title">MAIL</h3>
                    <p className="footer__section-text">
                        E-mail: sit@intuit.kg
                    </p>
                </div>
                <div className="footer__section">
                    <h3 className="footer__section-title">SITE</h3>
                    <p className="footer__section-text">
                        <a href="https://www.intuit.kg" className="footer__link">www.intuit.kg</a>
                    </p>
                </div>
            </footer>
        </div>

    );
}


export default App;



