//React
import React, {useEffect, useState} from "react";

//Packages
import axios from "axios";

//Local
import "../Documentation/DocsStyle.css"
import ArchiveItems from "./ArchiveList/ArchiveItems";
import CustomAccordion from "../UI/accordion/Accordion";
import ArchiveLink from "./ArchiveList/ArchiveLink";

//Config
import config from "../../Config";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {Box} from "@mui/material";


const TitleList = ({navigate}) => {

    const [AccessToken, setStoredToken] = useState("");

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const storedToken = localStorage.getItem("accessToken");
        setStoredToken(storedToken);

        // Функция для загрузки данных с API
        const fetchPosts = async () => {
            try {
                const response = await axios.get(config.apiUrl + '/posts/post-load/',);

                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        // Вызываем функцию загрузки данных при монтировании компонента
        fetchPosts().then(_ => {
        });
    }, []);


    return (

        <Container component="main" maxWidth="lg" sx={{mb: 4}}>
            <Paper className='blockText_docs' elevation={24} style={{backgroundColor: "#f0f0f0"}} variant="outlined"
                   sx={{my: {xs: 3, md: 6},}}>
                <Box className='text_docs'>

                    <CustomAccordion
                        title={"Статьи на рассмотрении"}
                        content={
                            posts
                                .filter((post) => !post["is_published"])
                                .map((post, index) => (
                                    <div key={post.id}>
                                        <ArchiveItems navigate={navigate} stoken={AccessToken} number={index + 1}
                                                      post={post}/>
                                    </div>
                                ))
                        }
                    />

                    <CustomAccordion
                        title={"Выпуск №31 (2кв. 2024г.)"}
                        content={
                            posts
                                .filter((post) => post["is_published"])
                                .map((post, index) => (
                                    <div key={post.id}>
                                        <ArchiveItems navigate={navigate} number={index + 1} post={post}/>
                                    </div>
                                ))
                        }
                    />

                    <CustomAccordion
                        title={"Выпуск №30 (1кв. 2024г.)"}
                        content={
                            posts
                                .filter((post) => post["is_published"])
                                .map((post, index) => (
                                    <div key={post.id}>
                                        <ArchiveItems navigate={navigate} number={index + 1} post={post}/>
                                    </div>
                                ))
                        }
                    />



                    <CustomAccordion
                        title={"Выпуск №29 (4кв. 2023г.)"}
                        content={
                            posts
                                .filter((post) => post["is_published"])
                                .map((post, index) => (
                                    <div key={post.id}>
                                        <ArchiveItems navigate={navigate} number={index + 1} post={post}/>
                                    </div>
                                ))
                        }
                    />

                    <CustomAccordion

                        title={"Выпуск №28 (3кв. 2023г.)"}
                        content={
                            <div>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Абдраимов-Э.С.-Бакиров-Б.Б.-Шадиев-М.И.-ИССЛЕДОВАНИЕ-ПОЛОМОК-В-УДАРНЫХ-МЕХАНИЗМАХ.pdf"
                                    strong="ИССЛЕДОВАНИЕ ПОЛОМОК В УДАРНЫХ МЕХАНИЗМАХ ПЕРЕМЕННОЙ СТРУКТУРЫ И НОВЫЕ ПОДХОДЫ К КОНСТРУИРОВАНИЮ"
                                    em="Абдраимов Э.С., Бакиров Б.Б., Шадиев М.И." other="(5-13 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Албанбаева-Дж.О.-ЭТАЛОННАЯ-МОДЕЛЬ-ПЕДАГОГИЧЕСКОГО-МОНИТОРИНГА-В-ВУЗЕ.pdf"
                                    strong="ЭТАЛОННАЯ МОДЕЛЬ ПЕДАГОГИЧЕСКОГО МОНИТОРИНГА УСПЕШНОСТИ ОБРАЗОВАТЕЛЬНОГО ПРОЦЕССА В ВУЗЕ"
                                    em="Албанбаева Дж.О." other="(13-27 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Ауельбекова-М.-Краскова-Е.-Касымова-Л.Н.-ИНТЕРНЕТ-КАК-ОДИН-ИЗ-ФАКТОРОВ-ПОЯВЛЕНИЯ-ДЕВИАНТНОГО-ПОВЕДЕНИЯ.pdf"
                                    strong="ИНТЕРНЕТ КАК ОДИН ИЗ ФАКТОРОВ ПОЯВЛЕНИЯ ДЕВИАНТНОГО ПОВЕДЕНИЯ"
                                    em="Ауельбекова М., Краскова Е., Касымова Л.Н." other="(27-39 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Бакытова-А.И.-СОВЕРШЕНСТВОВАНИЕ-ТАРИФОВ-НА-УСЛУГИ-МОБИЛЬНОЙ-СВЯЗИ-КР.pdf"
                                    strong="СОВЕРШЕНСТВОВАНИЕ ТАРИФОВ НА УСЛУГИ МОБИЛЬНОЙ СВЯЗИ КР" em="Бакытова А.И."
                                    other="(39-44 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Борбучалова-Б.Ж.-ОДИН-ИЗ-САТИРИЧЕСКИХ-ЖАНРОВ-–-ПАРОДИИ.pdf"
                                    strong="ОДИН ИЗ САТИРИЧЕСКИХ ЖАНРОВ – ПАРОДИИ" em="Борбучалова Б.Ж."
                                    other="(44-52 стр.)"/>

                                <ArchiveLink href="/articles/sit/2023/11/Борбучалова-Б.Ж.-САТИРИК-–-АБЗИЙ-КЫДЫРОВ.pdf"
                                             strong="САТИРИК – АБЗИЙ КЫДЫРОВ" em="Борбучалова Б.Ж."
                                             other="(52-59 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Джоодатбекова-Ж.-Кенешбаева-М.М.-ТЕХНОПАРКИ-СТИМУЛИРУЮТ-РАЗВИТИЕ-РЕГИОНОВ.pdf"
                                    strong="ТЕХНОПАРКИ СТИМУЛИРУЮТ РАЗВИТИЕ РЕГИОНОВ"
                                    em="Джоодатбекова Ж., Кенешбаева М.М."
                                    other="(59-63 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Каримов-А.А.-Картанова-А.Дж.-РАЗРАБОТКА-ИНФОРМАЦИОННОЙ-СИСТЕМЫ.pdf"
                                    strong="РАЗРАБОТКА ИНФОРМАЦИОННОЙ СИСТЕМЫ «ЭПИДЕМИОЛОГИЧЕСКАЯ ПЛАТФОРМА»"
                                    em="Каримов А.А., Картанова А.Дж." other="(63-71 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Картанова-А.Дж.-Максатбеков-Д.М.-ОБЗОР-МЕТОДОВ-МАШИННОГО-ОБУЧЕНИЯ.pdf"
                                    strong="ОБЗОР МЕТОДОВ МАШИННОГО ОБУЧЕНИЯ ДЛЯ МОДЕЛИРОВАНИЯ ПРОФИЛЯ СТУДЕНТОВ"
                                    em="Картанова А.Дж., Максатбеков Д.М." other="(71-81 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Матыева-А.К.-Мелибаев-С.Ж.-Асаналиева-Ж.Д.-ФИЗИКО-ХИМИЧЕСКИЕ-ИССЛЕДОВАНИЯ-ПРИРОДНОГО-И-ТЕХНОГЕННОГО-КРЕМНЕЗЕМИСТОГО-СЫРЬЯ.pdf"
                                    strong="ФИЗИКО-ХИМИЧЕСКИЕ ИССЛЕДОВАНИЯ ПРИРОДНОГО И ТЕХНОГЕННОГО КРЕМНЕЗЕМИСТОГО СЫРЬЯ ДЛЯ НЕАВТОКЛАВНОГО ГАЗОБЕТОНА"
                                    em="Матыева А.К., Мелибаев С.Ж., Асаналиева Ж.Д." other="(81-87 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Муканбетов-С.М.-Картанова-А.Дж.-РАЗРАБОТКА-БАЗЫ-ДАННЫХ-СОТРУДНИКОВ-МЕДИЦИНСКИХ-УЧРЕЖДЕНИЙ.pdf"
                                    strong="РАЗРАБОТКА БАЗЫ ДАННЫХ СОТРУДНИКОВ МЕДИЦИНСКИХ УЧРЕЖДЕНИЙ КЫРГЫЗСКОЙ РЕСПУБЛИКИ"
                                    em="Муканбетов С.М., Картанова А.Дж." other="(87-95 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Рахманов-А.Р.-ПРАВОВОЙ-СТАТУС-ДЕПУТАТСКИХ-ОБЪЕДИНЕНИЙ-ЖОГОРКУ-КЕНЕША-КР.pdf"
                                    strong="ПРАВОВОЙ СТАТУС ДЕПУТАТСКИХ ОБЪЕДИНЕНИЙ ЖОГОРКУ КЕНЕША КЫРГЫЗСКОЙ РЕСПУБЛИКИ: ПРОБЛЕМЫ И ПУТИ ИХ РЕШЕНИЯ"
                                    em="Рахманов А.Р." other="(95-100 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Сартбекова-К.Н.-Нышанбаева-М.Ж.-КРИТИЧЕСКОЕ-МЫШЛЕНИЕ-И-ЕГО-ДРЕВНИЙ-СТАТУС-СРЕДСТВА-И-МЕТОДЫ.pdf"
                                    strong="КРИТИЧЕСКОЕ МЫШЛЕНИЕ И ЕГО ДРЕВНИЙ СТАТУС, СРЕДСТВА И МЕТОДЫ"
                                    em="Сартбекова К.Н., Нышанбаева М.Ж." other="(100-106 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Сартбекова-Н.К.-Таникулова-А.М.-СОВРЕМЕННЫЕ-МЕТОДЫ-ФОРМИРОВАНИЯ-ИССЛЕДОВАТЕЛЬСКОЙ-ДЕЯТЕЛЬНОСТИ.pdf"
                                    strong="СОВРЕМЕННЫЕ МЕТОДЫ ФОРМИРОВАНИЯ ИССЛЕДОВАТЕЛЬСКОЙ ДЕЯТЕЛЬНОСТИ В МЫШЛЕНИИ СТУДЕНТОВ КОЛЛЕДЖА"
                                    em="Сартбекова Н.К., Таникулова А.М." other="(106-111 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Султанова-С.Р.-Каримбаев-Т.Т.-СОВРЕМЕННЫЕ-ИНФОРМАЦИОННЫЕ-СИСТЕМЫ-В-ОБРАЗОВАНИИ.pdf"
                                    strong="СОВРЕМЕННЫЕ ИНФОРМАЦИОННЫЕ СИСТЕМЫ В ОБРАЗОВАНИИ"
                                    em="Султанова С.Р., Каримбаев Т.Т." other="(111-116 стр.)"/>

                                <ArchiveLink
                                    href="/articles/sit/2023/11/Ташболотов-Б.Б.-Картанова-А.Дж.-РАЗРАБОТКА-ИНФОРМАЦИОННОЙ-СИСТЕМЫ.pdf"
                                    strong="РАЗРАБОТКА ИНФОРМАЦИОННОЙ СИСТЕМЫ «МЕДИЦИНСКОЕ СВИДЕТЕЛЬСТВО»"
                                    em="Ташболотов Б.Б., Картанова А.Дж." other="(116-123 стр.)"/>

                            </div>
                        }
                    />

                    <CustomAccordion title={"Выпуск №27 (2кв. 2023г.)"} content={
                        <div>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Абдикеримова-Ж.Т.-ПРОБЛЕМА-ОЦЕНКИ-КАЧЕСТВА-ОБРАЗОВАНИЯ-В-ДЕЯТЕЛЬНОСТИ-АККРЕДИТАЦИОННОГО-АГЕНТСТВА.pdf"
                                strong="ПРОБЛЕМА ОЦЕНКИ КАЧЕСТВА ОБРАЗОВАНИЯ В  ДЕЯТЕЛЬНОСТИ АККРЕДИТАЦИОННОГО  АГЕНТСТВА «САПАТТУУ БИЛИМ»"
                                em="Абдикеримова Ж.Т." other="(3-11 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Абдуллаев-А.З.-ОПИСАНИЕ-ПРОЦЕССА-ЭЛЕКТРОХИМИЧЕСКОЙ-ОЧИСТКИ-НЕФТЕЗАГРЯЗНЕННЫХ-ГРУНТОВ.pdf"
                                strong="ОПИСАНИЕ ПРОЦЕССА ЭЛЕКТРОХИМИЧЕСКОЙ ОЧИСТКИ НЕФТЕЗАГРЯЗНЕННЫХ ГРУНТОВ С ПРИМЕНЕНИЕМ МАТЕМАТИЧЕСКИХ МЕТОДОВ"
                                em="Абдуллаев А.З." other="(11-15 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Абдурахманов-С.К.-Оморов-Н.А.-Мирзакматов-К.Ж.-АНАЛИЗ-ВЛИЯНИЕ-ВОЗРАСТА-ВОДИТЕЛЯ-НА-БЕЗОПАСНОСТЬ-ДВИЖЕНИЯ.pdf"
                                strong="АНАЛИЗ ВЛИЯНИЕ ВОЗРАСТА ВОДИТЕЛЯ НА БЕЗОПАСНОСТЬ ДВИЖЕНИЯ В СФЕРЕ АВТОТРАНСПОРТНОГО ОБЩЕГО ПОЛЬЗОВАНИЯ"
                                em="Абдурахманов С.К., Оморов Н.А., Мирзакматов К.Ж." other=" (15-21 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Абылгазиев-Э.М.-Бакиров-Н.Б.-Бабаев-А.Н.-Ибраимов-И.Х.-ПРОЕКТИРОВАНИЕ-ЭЛЕКТРОННОГО-КУРСА-ДЛЯ-ОБУЧЕНИЯ-ВЕБ-ДИЗАЙНУ.pdf"
                                strong="ПРОЕКТИРОВАНИЕ ЭЛЕКТРОННОГО КУРСА ДЛЯ ОБУЧЕНИЯ ВЕБ-ДИЗАЙНУ"
                                em="Абылгазиев Э.М., Бакиров Н.Б., Бабаев А.Н., Ибраимов И.Х." other=" (21-25 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Акбашева-А.М.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-ПРОЦЕССОМ-ХЛОРИРОВАНИЯ.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ПРОЦЕССОМ ХЛОРИРОВАНИЯ РЕЦИКЛОВОГО ДИХЛОРЭТАНА"
                                em="Акбашева А.М." other=" (25-29 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Акматжан-уулу-Д.-Сарбаева-Н.М.-КЕРАМИЧЕСКИЕ-СТЕНОВЫЕ-БЛОКИ-НА-ОСНОВЕ-ОТХОДОВ-СЕЛЬСКОХОЗЯЙСТВЕННОГО-РЕСУРСА.pdf"
                                strong="КЕРАМИЧЕСКИЕ СТЕНОВЫЕ БЛОКИ НА ОСНОВЕ ОТХОДОВ СЕЛЬСКОХОЗЯЙСТВЕННОГО РЕСУРСА"
                                em="Акматжан уулу Д., Сарбаева Н.М." other=" (29-34 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Арзыбаев-А.А.-КАПИТАЛ-ПРЕДПРИЯТИЯ-СУЩНОСТЬ-И-ЗНАЧЕНИЕ-В-НОВЫХ-УСЛОВИЯХ-ХОЗЯЙСТВОВАНИЯ.pdf"
                                strong="КАПИТАЛ ПРЕДПРИЯТИЯ: СУЩНОСТЬ И ЗНАЧЕНИЕ В НОВЫХ УСЛОВИЯХ ХОЗЯЙСТВОВАНИЯ"
                                em="Арзыбаев А. А." other=" (34-40 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Бейшеналиева-А.Н.-Албанбаева-Дж.О.-ЗНАЧИМОСТЬ-РЕКЛАМНОГО-ДИСКУРСА-В-ИННОВАЦИОННОЙ-СОВРЕМЕННОСТИ.pdf"
                                strong="ЗНАЧИМОСТЬ РЕКЛАМНОГО ДИСКУРСА В ИННОВАЦИОННОЙ СОВРЕМЕННОСТИ"
                                em="Бейшеналиева А.Н., Албанбаева Дж.О." other=" (40-47 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Биймырсаева-Э.М.-Биймырсаева-А.К.-АНАЛИЗ-УБОРКИ-УРОЖАЯ-СЕЛЬСКОХОЗЯЙСТВЕННЫХ-КУЛЬТУР-В-КР.pdf"
                                strong="АНАЛИЗ УБОРКИ УРОЖАЯ СЕЛЬСКОХОЗЯЙСТВЕННЫХ КУЛЬТУР В КЫРГЫЗСКОЙ РЕСПУБЛИКЕ "
                                em="Биймырсаева Э.М., Биймырсаева А. К." other=" (47-53 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Биймырсаева-Э.М.-Жыргалбекова-К.Ж.-ОЦЕНКА-И-АНАЛИЗ-ДЕЛОВОЙ-АКТИВНОСТИ-ИННОВАЦИОННОЙ-ДЕЯТЕЛЬНОСТИ.pdf"
                                strong="ОЦЕНКА И АНАЛИЗ ДЕЛОВОЙ АКТИВНОСТИ ИННОВАЦИОННОЙ ДЕЯТЕЛЬНОСТИ ПРОМЫШЛЕННОГО ПРЕДПРИЯТИЯ В УСЛОВИЯХ РЫНКА "
                                em="Биймырсаева Э.М., Жыргалбекова К.Ж." other=" (53-59 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Бимықова-Д.М.-Куатбаева-Т.К.-ИССЛЕДОВАНИЕ-ЭФФЕКТИВНОСТИ-ИНГИБИТОРА-ТАРДИОЛА.pdf"
                                strong="ИССЛЕДОВАНИЕ ЭФФЕКТИВНОСТИ ИНГИБИТОРА ТАРДИОЛА"
                                em="Бимықова Д.М., Куатбаева Т.К."
                                other=" (59-66 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Биримкулова-К.Д.-Качканбаева-А.С.-Жылдызбекова-А.Ж.-МАРЖИНАЛЬНЫЙ-ПОДХОД-КАК-ИНСТРУМЕНТ-ПРИНЯТИЯ-УПРАВЛЕНЧЕСКИХ.pdf"
                                strong="МАРЖИНАЛЬНЫЙ ПОДХОД КАК ИНСТРУМЕНТ ПРИНЯТИЯ УПРАВЛЕНЧЕСКИХ РЕШЕНИЙ ПРИ УСТОЙЧИВОМ РАЗВИТИИ"
                                em="Биримкулова К.Д., Качканбаева А.С., Жылдызбекова А.Ж." other=" (66-70 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Ван-Жуй-ОСОБЕННОСТИ-ТРАДИЦИОННОЙ-НАРОДНОЙ-МУЗЫКИ-КР.pdf"
                                strong="ОСОБЕННОСТИ ТРАДИЦИОННОЙ НАРОДНОЙ МУЗЫКИ КЫРГЫЗСТАНА" em="Ван Жуй"
                                other=" (70-75 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Галиева-Р.Р.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-ОТДЕЛЕНИЕМ-ВЫПАРКИ.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ОТДЕЛЕНИЕМ ВЫПАРКИ В ПРОИЗВОДСТВЕ НАТРА ЕДКОГО ТВЕРДОГО ЧЕШУИРОВАННОГО"
                                em="Галиева Р.Р." other=" (75-80 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Дженбаева-А.Б.-Турдукулов-Ф.З.-НЕОБХОДИМОСТЬ-АНАЛИЗА-И-ОЦЕНКИ-ВНЕШНЕЙ-СРЕДЫ-ОРГАНИЗАЦИИ.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ОТДЕЛЕНИЕМ ВЫПАРКИ В ПРОИЗВОДСТВЕ НАТРА ЕДКОГО ТВЕРДОГО ЧЕШУИРОВАННОГО"
                                em="Дженбаева А.Б., Турдукулов Ф.З. " other=" (80-86 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Джузбаева-А.Н.-СУЩНОСТНАЯ-ХАРАКТЕРИСТИКА-ДЕКОРАТИВНО-ПРИКЛАДНОГО-ИСКУССТВА.pdf"
                                strong="СУЩНОСТНАЯ ХАРАКТЕРИСТИКА ДЕКОРАТИВНО-ПРИКЛАДНОГО ИСКУССТВА "
                                em="Джузбаева А.Н."
                                other=" (86-92 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Дуйшеналиева-А.Дж.-Аттокурова-А.А.-Риферт-К.П.-Дыйканбаева-Н.А.-ОСНОВНЫЕ-АСПЕКТЫ-КАДРОВОГО-ПЛАНИРОВАНИЯ.pdf"
                                strong="ОСНОВНЫЕ АСПЕКТЫ КАДРОВОГО ПЛАНИРОВАНИЯ НА ПРЕДПРИЯТИИ"
                                em="Дуйшеналиева А.Дж., Аттокурова А.А., Риферт К.П., Дыйканбаева Н.А."
                                other=" (92-97 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Дуйшеналиева-А.Дж.-Аттокурова-А.А.-Риферт-К.П.-Масирова-С.К.-Чжун-Юн-МАРКЕТИНГОВЫЕ-СТРАТЕГИИ-КАК-ИНСТРУМЕНТ.pdf"
                                strong="МАРКЕТИНГОВЫЕ СТРАТЕГИИ КАК ИНСТРУМЕНТ УПРАВЛЕНИЯ КОНКУРЕНТОСПОСОБНОСТЬЮ ПРЕДПРИЯТИЯ"
                                em="Дуйшеналиева А.Дж., Аттокурова А.А., Риферт К.П., Масирова С.К., Чжун Юн"
                                other=" (97-102 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Есенаманов-Н.К.-МОДЕЛИРОВАНИЕ-ТЕХНОЛОГИИ-ВОЗВЕДЕНИЯ-МОНОЛИТНО-КАРКАСНОГО-МНОГОЭТАЖНОГО-ЖИЛОГО-ЗДАНИЯ.pdf"
                                strong="МОДЕЛИРОВАНИЕ ТЕХНОЛОГИИ ВОЗВЕДЕНИЯ МОНОЛИТНО-КАРКАСНОГО МНОГОЭТАЖНОГО ЖИЛОГО ЗДАНИЯ"
                                em="Есенаманов Н.К." other="(102-109 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Зейнуллина-А.Ж.-Ибраимова-А.Т.-Астраханцева-Е.А.-Габдуллина-Л.Б.-РАЗВИТИЕ-ИННОВАЦИОННОГО-ПОТЕНЦИАЛА-ПРЕДПРИЯТИИ-ТРАНСПОРТНО.pdf"
                                strong="РАЗВИТИЕ ИННОВАЦИОННОГО ПОТЕНЦИАЛА ПРЕДПРИЯТИИ ТРАНСПОРТНО-ЛОГИСТИЧЕСКОЙ СИСТЕМЫ КАЗАХСТАНА"
                                em="Зейнуллина А.Ж., Ибраимова А.Т., Астраханцева Е.А., Габдуллина Л.Б."
                                other=" (109-116 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Ильин-С.А.-СИСТЕМА-УПРАВЛЕНИЯ-ГАЗОГЕНЕРАТОРНЫМИ-УСТАНОВКАМИ.pdf"
                                strong="СИСТЕМА УПРАВЛЕНИЯ ГАЗОГЕНЕРАТОРНЫМИ УСТАНОВКАМИ" em="Ильин С.А."
                                other=" (116-122 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Кадыров-Р.Р.-Масаков-Е.М.-АКТУАЛЬНОСТЬ-ВНЕДРЕНИЯ-УСОВЕРШЕНСТВОВАННОГО-УПРАВЛЕНИЯ-ТЕХНОЛОГИЧЕСКИМИ-ПРОЦЕССАМИ.pdf"
                                strong="АКТУАЛЬНОСТЬ ВНЕДРЕНИЯ УСОВЕРШЕНСТВОВАННОГО УПРАВЛЕНИЯ ТЕХНОЛОГИЧЕСКИМИ ПРОЦЕССАМИ НА НЕФТЕПЕРЕРАБАТЫВАЮЩИХ И ХИМИЧЕСКИХ ЗАВОДАХ"
                                em="Кадыров Р.Р., Масаков Е.М." other=" (122-126 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Калдыбаев-Н.А.-Мамытов-А.С.-Акылбек-кызы-Д.-ДЕКОРАТИВНЫЙ-ПУСТОТНО-ПОРИСТЫЙ-КЕРАМИЧЕСКИЙ-БЛОК.pdf"
                                strong="ДЕКОРАТИВНЫЙ ПУСТОТНО-ПОРИСТЫЙ КЕРАМИЧЕСКИЙ БЛОК ДЛЯ ВОЗВЕДЕНИЯ ОДНОСЛОЙНЫХ СТЕН"
                                em="Калдыбаев Н.А., Мамытов А.С., Акылбек кызы Д." other="(126-133 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Капаков-Б.Ж.-СОДЕЙСТВИЕ-ИННОВАЦИЯМ-И-ИНВЕСТИЦИЮ-В-КР-1.pdf"
                                strong="СОДЕЙСТВИЕ ИННОВАЦИЯМ И ИНВЕСТИЦИЮ В КЫРГЫЗСТАНЕ: ПРОБЛЕМЫ И ВОЗМОЖНОСТИ"
                                em="Капаков Б.Ж." other=" (133-142 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Качкын-уулу-А.-Токтогонова-Д.Ж.-Бактыбек-кызы-В.-КОРРЕКЦИЯ-ТРЕВОЖНОСТИ-У-ДЕТЕЙ-МЛАДШЕГО-ШКОЛЬНОГО-ВОЗРАСТА.pdf"
                                strong="КОРРЕКЦИЯ ТРЕВОЖНОСТИ У ДЕТЕЙ МЛАДШЕГО ШКОЛЬНОГО ВОЗРАСТА"
                                em="Качкын уулу А., Токтогонова Д.Ж., Бактыбек кызы В." other=" (142-149 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Кенжеханова-Ж.Б.-Акмалайулы-К.-КОМПОЗИЦИОННЫЙ-ЯЧЕИСТЫЙ-БЕТОН-АРМИРОВАННЫЙ-ФИБРОЙ.pdf"
                                strong="КОМПОЗИЦИОННЫЙ ЯЧЕИСТЫЙ БЕТОН АРМИРОВАННЫЙ ФИБРОЙ"
                                em="Кенжеханова Ж.Б., Акмалайулы К." other=" (149-155 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Колбаева-Б.К.-ПОДХОДЫ-К-ФОРМИРОВАНИЮ-КОНКУРЕНТНОЙ-СТРАТЕГИИ-ПРЕДПРИЯТИЯ.pdf"
                                strong="ПОДХОДЫ К ФОРМИРОВАНИЮ КОНКУРЕНТНОЙ СТРАТЕГИИ ПРЕДПРИЯТИЯ " em="Колбаева Б.К."
                                other=" (155-163 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Кочконбаева-К.З.-НЕКОТОРЫЕ-ВОПРОСЫ-ЭКОНОМИЧЕСКОГО-РАЗВИТИЯ-РЕГИОНОВ-КР.pdf"
                                strong="НЕКОТОРЫЕ ВОПРОСЫ ЭКОНОМИЧЕСКОГО РАЗВИТИЯ РЕГИОНОВ КЫРГЫЗСКОЙ РЕСПУБЛИКИ"
                                em="Кочконбаева К.З." other=" (163-168 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Латыпова-Э.Р.-Киселева-А.А.-ИСТОРИЧЕСКИЕ-ПРЕДПОСЫЛКИ-СТАНОВЛЕНИЯ-АНГЛИЙСКОГО-ЯЗЫК-КАК-МЕЖДУНАРОДНОГО.pdf"
                                strong="ИСТОРИЧЕСКИЕ ПРЕДПОСЫЛКИ СТАНОВЛЕНИЯ АНГЛИЙСКОГО ЯЗЫК КАК МЕЖДУНАРОДНОГО"
                                em="Латыпова Э.Р., Киселева А.А. " other="(168-175 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Мергенбаева-С.Р.-О-ЛИНГВОДИДАКТИЧЕСКИХ-ОСНОВАХ-ОБУЧЕНИЯ-КЫРГЫЗСКОМУ-ЯЗЫКУ-ДЛЯ-ИНОСТРАННЫХ-ШКОЛЬНИКОВ.pdf"
                                strong="О ЛИНГВОДИДАКТИЧЕСКИХ ОСНОВАХ ОБУЧЕНИЯ КЫРГЫЗСКОМУ ЯЗЫКУ ДЛЯ ИНОСТРАННЫХ ШКОЛЬНИКОВ"
                                em="Мергенбаева С.Р." other=" (175-180 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Муратова-А.М.-Оморкулов-Н.О.-ИННОВАЦИОННЫЙ-ПОДХОД-К-ВНЕДРЕНИЮ-ЗДОРОВЬЕСБЕРЕГАЮЩИХ-ТЕХНОЛОГИЙ.pdf"
                                strong="ИННОВАЦИОННЫЙ ПОДХОД К ВНЕДРЕНИЮ ЗДОРОВЬЕСБЕРЕГАЮЩИХ ТЕХНОЛОГИЙ НА МУЗЫКАЛЬНЫХ ЗАНЯТИЯХ В ДЕТСКОМ САДУ"
                                em="Муратова А.М., Оморкулов Н.О." other=" (180-189 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Мусаева-В.А.-Затравина-С.В.-ПРЕИМУЩЕСТВА-И-НЕДОСТАТКИ-РАЗВИТИЯ-АУТСОРСИНГА-В-СОВРЕМЕННЫХ-УСЛОВИЯХ.pdf"
                                strong="ПРЕИМУЩЕСТВА И НЕДОСТАТКИ РАЗВИТИЯ АУТСОРСИНГА В СОВРЕМЕННЫХ УСЛОВИЯХ"
                                em="Мусаева В.А., Затравина С.В." other=" (189-197 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Николаева-А.И.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-ПРОЦЕССОМ-ПИРОЛИЗА-ДИХЛОРЭТАНА.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ПРОЦЕССОМ ПИРОЛИЗА ДИХЛОРЭТАНА"
                                em="Николаева А.И." other=" (197-201 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Омурзаков-А.-Сарбаева-Н.М.-СРАВНИТЕЛЬНЫЙ-АНАЛИЗ-СОВРЕМЕННЫХ-ТЕПЛОИЗОЛЯЦИОННЫХ-МАТЕРИАЛОВ.pdf"
                                strong="СРАВНИТЕЛЬНЫЙ АНАЛИЗ СОВРЕМЕННЫХ ТЕПЛОИЗОЛЯЦИОННЫХ МАТЕРИАЛОВ "
                                em="Омурзаков А., Сарбаева Н.М." other=" (201-205 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Осмонова-Ж.Б.-Чапаева-Н.Д.-ОЦЕНКА-ПОТЕНЦИАЛА-СТРАН-ЕАЭС-в-ПРОИЗВОДСТВЕ-ОСНОВНЫХ-ПРОДУКТОВ-ПИТАНИЯ.pdf"
                                strong="ОЦЕНКА ПОТЕНЦИАЛА СТРАН ЕАЭС в ПРОИЗВОДСТВЕ ОСНОВНЫХ ПРОДУКТОВ ПИТАНИЯ"
                                em="Осмонова Ж.Б., Чапаева Н.Д. " other=" (205-213 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Родионов-Г.А.-МАТЕМАТИЧЕСКАЯ-МОДЕЛЬ-ДЛЯ-СОСТАВЛЕНИЯ-ПРОГНОЗА-КАЧЕСТВА-АТМОСФЕРЫ.pdf"
                                strong="МАТЕМАТИЧЕСКАЯ МОДЕЛЬ ДЛЯ СОСТАВЛЕНИЯ ПРОГНОЗА КАЧЕСТВА АТМОСФЕРЫ"
                                em="Родионов Г.А." other=" (213-218 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Сабитов-Б.Р.-Керимов-У.Т.-Ачекеев-К.С.-Мусуралиева-Д.Э.-Мустапакулов-Н.К.-ИСПОЛЬЗОВАНИЕ-АЛГОРИТМОВ-МАШИННОГО-ОБУЧЕНИЯ.pdf"
                                strong="ИСПОЛЬЗОВАНИЕ АЛГОРИТМОВ МАШИННОГО ОБУЧЕНИЯ ДЛЯ ПОСТРОЕНИЯ ЛИНЕЙНЫХ МОДЕЛЕЙ ЗАДАЧ СЕЛЬСКОГО ХОЗЯЙСТВА"
                                em="Сабитов Б.Р., Керимов У.Т., Ачекеев К.С., Мусуралиева Д.Э., Мустапакулов Н.К. "
                                other=" (218-224 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Садыков-А.М.-ПРЕИМУЩЕСТВА-ИСПОЛЬЗОВАНИЯ-ПЛАТФОРМЫ-WORDPRESS.pdf"
                                strong="ПРЕИМУЩЕСТВА ИСПОЛЬЗОВАНИЯ ПЛАТФОРМЫ WORDPRESS ДЛЯ РАЗРАБОТКИ КОРПОРАТИВНОГО САЙТА"
                                em="Садыков А.М." other=" (224-232 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Сартбекова-Н.К.-Абакулов-Р.А.-К-ВОПРОСУ-О-БИЛИНГВИЗМЕ-В-КР.pdf"
                                strong="К ВОПРОСУ О БИЛИНГВИЗМЕ В КЫРГЫЗСТАНЕ" em="Сартбекова Н.К., Абакулов Р.А. "
                                other=" (232-239 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Суйналиева-Н.К.-Байышбек-к.А.-АВТОМАТИЗАЦИЯ-БИЗНЕС-ПРОЦЕССОВ-НА-ПЛАТФОРМЕ-1CПРЕДПРИЯТИЕ.pdf"
                                strong="АВТОМАТИЗАЦИЯ БИЗНЕС-ПРОЦЕССОВ НА ПЛАТФОРМЕ 1C:ПРЕДПРИЯТИЕ"
                                em="Суйналиева Н.К., Байышбек к.А. " other=" (239-243 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Суйналиева-Н.К.-Сеиткожоева-А.Т.-ЦИФРОВАЯ-ТРАНСФОРМАЦИЯ-ЭКОНОМИКИ-КАК-НОВЫЙ-ВЫЗОВ-ЭБ.pdf"
                                strong="ЦИФРОВАЯ ТРАНСФОРМАЦИЯ ЭКОНОМИКИ КАК НОВЫЙ ВЫЗОВ ЭКОНОМИЧЕСКОЙ БЕЗОПАСНОСТИ"
                                em="Суйналиева Н.К., Сеиткожоева А.Т." other=" (243-249 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Султанбаева-Г.С.-Ачекеев-К.С.-Барганалиева-Ж.К.-Ырысбай-кызы-Ж.-СОЗДАНИЕ-ГРАФИЧЕСКИХ-ИЗОБРАЖЕНИЙ-С-ПОМОЩЬЮ-БИБЛИОТЕКИ-TURTLE-1.pdf"
                                strong="СОЗДАНИЕ ГРАФИЧЕСКИХ ИЗОБРАЖЕНИЙ С ПОМОЩЬЮ БИБЛИОТЕКИ TURTLE НА ЯЗЫКЕ PYTHON "
                                em="Султанбаева Г.С., Ачекеев К.С., Барганалиева Ж.К., Ырысбай кызы Ж."
                                other=" (249-257 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Султанова-С.Р.-Каримбаев-Т.Т.-СОВРЕМЕННЫЕ-ИНФОРМАЦИОННЫЕ-СИСТЕМЫ-В-ОБРАЗОВАНИИ-.pdf"
                                strong="СОВРЕМЕННЫЕ ИНФОРМАЦИОННЫЕ СИСТЕМЫ В ОБРАЗОВАНИИ"
                                em="Султанова С.Р., Каримбаев Т.Т." other=" (257-264 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Тагаева-С.Б.-УСЛОВИЯ-ГЛАДКОСТИ-РЕШЕНИЙ-СИСТЕМ-НЕЛИНЕЙНЫХ-ОБЫКНОВЕННЫХ-ДИФФЕРЕНЦИАЛЬНЫХ-УРАВНЕНИЙ.pdf"
                                strong="УСЛОВИЯ ГЛАДКОСТИ РЕШЕНИЙ СИСТЕМ НЕЛИНЕЙНЫХ ОБЫКНОВЕННЫХ ДИФФЕРЕНЦИАЛЬНЫХ УРАВНЕНИЙ С РАЗРЫВНЫМИ ПРАВЫМИ ЧАСТЯМИ "
                                em="Тагаева С.Б." other=" (264-270 стр.)"/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Такиуллина-Д.Т.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-ПРОЦЕССОМ-ОКИСЛИТЕЛЬНОГО-ХЛОРИРОВАНИЯ-ЭТИЛЕНА.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ПРОЦЕССОМ ОКИСЛИТЕЛЬНОГО ХЛОРИРОВАНИЯ ЭТИЛЕНА"
                                em="Такиуллина Д.Т." other=" (270-274 стр.) "/>

                            <ArchiveLink
                                href="/articles/sit/2023/11/Ташмурзаева-М.Э.-СВЯЗЬ-МЕЖДУ-РАЗВИТИЕМ-ИНФОРМАЦИОННЫХ-ТЕХНОЛОГИЙ-И-ОБЩЕНИЕМ-ПОДРОСТКОВ.pdf"
                                strong="СВЯЗЬ МЕЖДУ РАЗВИТИЕМ ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ И ОБЩЕНИЕМ ПОДРОСТКОВ"
                                em="Ташмурзаева М.Э. " other=" (274-283 стр.)"/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Тенгебаев-Н.Е.-Бесімбаева-Л.Е.-Сыздыкова-А.С.-Есілжанұлы-Н.-Закарьянов-А.Р.-СТРОИТЕЛЬНЫЙ-МОДУЛЬ-SATBAYEV-UNIVERSITY.pdf"
                                strong="СТРОИТЕЛЬНЫЙ МОДУЛЬ SATBAYEV UNIVERSITY: ПРЕИМУЩЕСТВА И ПЕРСТПЕКТИВА ПРИМЕНЕНИЯ"
                                em="Тенгебаев Н.Е., Бесімбаева Л.Е., Сыздыкова А.С., Есілжанұлы Н., Закарьянов А.Р."
                                other=" (283-291 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Тинасилов-М.Д.-Уркумбаева-А.Р.-Тинасилов-Р.М.-МОДЕРНИЗАЦИЯ-ФАРМАЦЕВТИЧЕСКОЙ-СФЕРЫ-В-РАЗВИТИИ-НАЦИОНАЛЬНОЙ-ЭКОНОМИКИ-РК.pdf"
                                strong="МОДЕРНИЗАЦИЯ ФАРМАЦЕВТИЧЕСКОЙ СФЕРЫ В РАЗВИТИИ НАЦИОНАЛЬНОЙ ЭКОНОМИКИ РК"
                                em="Тинасилов М.Д., Уркумбаева А.Р., Тинасилов Р.М." other=" (291-299 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Тинасилов-М.Д.-Уркумбаева-А.Р.-Тинасилов-Р.М.-ПСИХОЛОГО-ПЕДАГОГИЧЕСКАЯ-ДЕЯТЕЛЬНОСТЬ-МЕДИЦИНСКИХ-РАБОТНИКОВ.pdf"
                                strong="ПСИХОЛОГО-ПЕДАГОГИЧЕСКАЯ ДЕЯТЕЛЬНОСТЬ МЕДИЦИНСКИХ РАБОТНИКОВ В УСЛОВИЯХ КАЧЕСТВА ЖИЗНИ НАСЕЛЕНИЯ"
                                em="Тинасилов М.Д., Уркумбаева А.Р., Тинасилов Р.М." other=" (299-306 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Тургунбекова-К.Т.-Красиков-Д.К.-ТЕКУЩАЯ-СИТУАЦИЯ-И-ПЕРСПЕКТИВЫ-ИНФОРМАЦИОННОЕ-ОБЕСПЕЧЕНИЕ-ТАМОЖЕННОЙ-ДЕЯТЕЛЬНОСТИ.pdf"
                                strong="ТЕКУЩАЯ СИТУАЦИЯ И ПЕРСПЕКТИВЫ ИНФОРМАЦИОННОЕ ОБЕСПЕЧЕНИЕ ТАМОЖЕННОЙ ДЕЯТЕЛЬНОСТИ ПО УПРАВЛЕНИЮ ТАМОЖЕННОЙ СТОИМОСТЬЮ "
                                em="Тургунбекова К.Т., Красиков Д.К." other=" (306-311 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Усенова-Н.Э.-НЕКОТОРЫЕ-АСПЕКТЫ-КОММЕМОРАТИВНОСТИ-В-СВЕТЕ-ГЛОБАЛЬНЫХ-ПЕРЕМЕН-КЫРГЫЗСКОЙ-КУЛЬТУРЫ.pdf"
                                strong="НЕКОТОРЫЕ АСПЕКТЫ КОММЕМОРАТИВНОСТИ В СВЕТЕ ГЛОБАЛЬНЫХ ПЕРЕМЕН КЫРГЫЗСКОЙ КУЛЬТУРЫ "
                                em="Усенова Н.Э." other=" (311-317 стр.)"/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Фазылов-А.-ЭКСПЕРТНАЯ-СИСТЕМА-В-ПОМОЩЬ-ОБУЧЕНИЯ-ОПЕРАТОРОВ-ПРОГРАММИРОВАНИЮ-ОБРАБОТКИ-ИЗДЕЛИЯ.pdf"
                                strong="ЭКСПЕРТНАЯ СИСТЕМА В ПОМОЩЬ ОБУЧЕНИЯ ОПЕРАТОРОВ ПРОГРАММИРОВАНИЮ ОБРАБОТКИ ИЗДЕЛИЯ НА ТОКАРНОМ СТАНКЕ ЧИСЛОВЫМ ПРОГРАММНЫМ УПРАВЛЕНИЕМ"
                                em="Фазылов А." other=" (317-320 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Хажжаж-Хадиджа-ПАРОНИМИЯ-И-ЕЕ-УПОТРЕБЛЕНИЕ-В-СОВРЕМЕННОМ-АНГЛ.ЯЗ..pdf"
                                strong="ПАРОНИМИЯ И ЕЕ УПОТРЕБЛЕНИЕ В СОВРЕМЕННОМ АНГЛИЙСКОМ ЯЗЫКЕ " em="Хажжаж Хадиджа"
                                other=" (320-326 стр.)"/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Черикбаева-Н.Б.-ТЕХНОЛОГИЯ-РАЗВИТИЯ-КРИТИЧЕСКОГО-МЫШЛЕНИЯ-СТУДЕНТОВ.pdf"
                                strong="ТЕХНОЛОГИЯ РАЗВИТИЯ КРИТИЧЕСКОГО МЫШЛЕНИЯ СТУДЕНТОВ С ПОМОЩЬЮ МАНИПУЛИРОВАНИЯ СОЗНАНИЕМ"
                                em="Черикбаева Н.Б." other=" (326-332 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Шакиров-К.С.-ОСНОВНОЕ-СОДЕРЖАНИЕ-И-ФОРМИРОВАНИЕ-ПРЕДМЕТНЫХ-КОМПЕТЕНЦИЙ.pdf"
                                strong="ОСНОВНОЕ СОДЕРЖАНИЕ И ФОРМИРОВАНИЕ ПРЕДМЕТНЫХ КОМПЕТЕНЦИЙ НА УРОКАХ ИНФОРМАТИКИ"
                                em="Шакиров К.С." other=" (332-337 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Шаршебаев-А.А.-ОБ-ОСНОВНЫХ-МЕХАНИЗМАХ-СТРУКТУРНОГО-ПРЕОБРАЗОВАНИЯ-ЭКОНОМИКИ.pdf"
                                strong="ОБ ОСНОВНЫХ МЕХАНИЗМАХ СТРУКТУРНОГО ПРЕОБРАЗОВАНИЯ ЭКОНОМИКИ ЧУЙСКОЙ ОБЛАСТИ КЫРГЫЗСТАНА"
                                em="Шаршебаев А.А." other="  (337-341 стр.)"/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Ягафарова-Г.А.-Михайлов-А.О.-Байтимиров-И.Р.-Мавлютов-Д.Э.-Кидрасов-Р.З.-ЭПОХА-ВОЗРОЖДЕНИЯ.-ОСНОВНЫЕ-ЧЕРТЫ-И-ЦЕННОСТИ.pdf"
                                strong="ЭПОХА ВОЗРОЖДЕНИЯ: ОСНОВНЫЕ ЧЕРТЫ И ЦЕННОСТИ"
                                em="Ягафарова Г.А., Михайлов А.О., Байтимиров И.Р., Мавлютов Д.Э., Кидрасов Р.З."
                                other=" (341-345 стр.) "/>
                            <ArchiveLink
                                href="/articles/sit/2023/11/Ярмухамедов-А.Л.-Турдукулова-Э.Р.-ИНСТРУМЕНТЫ-ОЦЕНКИ-СТРАТЕГИЧЕСКОГО-ПОТЕНЦИАЛА-ОРГАНИЗАЦИИ.pdf"
                                strong="ИНСТРУМЕНТЫ ОЦЕНКИ СТРАТЕГИЧЕСКОГО ПОТЕНЦИАЛА ОРГАНИЗАЦИИ "
                                em="Ярмухамедов А.Л., Турдукулова Э.Р." other=" (345-350 стр.)"/>
                        </div>
                    }/>


                    <CustomAccordion title={"Выпуск №26 (1кв. 2023г.)"} content={
                        <div>
                            <ArchiveLink
                                href="/articles/sit/2023/09/Абакулов-Р.А.-ГОСУДАРСТВЕННАЯ-ПОЛИТИКА-В-ОБЛАСТИ-ЯЗЫКОВОГО-ОБРАЗОВАНИЯ-КР.pdf"
                                strong="ГОСУДАРСТВЕННАЯ ПОЛИТИКА В ОБЛАСТИ ЯЗЫКОВОГО ОБРАЗОВАНИЯ КЫРГЫЗСТАНА - "
                                em="Абакулов Р.А." other=" (3-3 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Абдыкалыкова-Т.Н.-НАЛОГОВАЯ-СИСТЕМА-УЧЕТА-И-АНАЛИЗА.pdf"
                                strong="НАЛОГОВАЯ СИСТЕМА УЧЕТА И АНАЛИЗА: НЕОБХОДИМОСТЬ И МЕХАНИЗМЫ ОРГАНИЗАЦИИ - "
                                em="Абдыкалыкова Т.Н." other=" (8-12 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Акылбекова-Э.К.-НЕКОТОРЫЕ-ВОПРОСЫ-ТРУДОВЫХ-РЕСУРСОВ-В-УСЛОВИЯХ.pdf"
                                strong="НЕКОТОРЫЕ ВОПРОСЫ ТРУДОВЫХ РЕСУРСОВ В УСЛОВИЯХ МИРОВОГО ЭКОНОМИЧЕСКОГО КРИЗИСА - "
                                em="Акылбекова Э.К." other=" (13-17 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Амандыков-А.Т.-Шаршенбекова-Т.Т.-ОЦЕНКИ-ЗАПАСОВ-ЛЕДНИКОВЫХ-ВОД-В-КР.pdf"
                                strong="ОЦЕНКИ ЗАПАСОВ ЛЕДНИКОВЫХ ВОД В КЫРГЫЗСТАНЕ - "
                                em="Амандыков А.Т., Шаршенбекова Т.Т." other=" (18-24 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Арзыбаева-Ж.А.-Турдалиева-К.Т.-ИННОВАЦИОННАЯ-ПОЛИТИКА-КР-МЕХАНИЗМЫ-ГОСУДАРСТВЕННОГО-СОДЕЙСТВИЯ,-КЛАСТЕРНОСТЬ,-ПРЕОБРАЗОВАНИЕ.pdf"
                                strong="ИННОВАЦИОННАЯ ПОЛИТИКА КР: МЕХАНИЗМЫ ГОСУДАРСТВЕННОГО СОДЕЙСТВИЯ, КЛАСТЕРНОСТЬ, ПРЕОБРАЗОВАНИЕ - "
                                em="Арзыбаева Ж.А., Турдалиева К.Т." other=" (25-30 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Ашимова-М.Р.-Рыспекова-А.Э.-АКТУАЛЬНЫЕ-ПРОБЛЕМЫ-МЕДИЦИНСКОЙ-ГЕНЕТИКИ.pdf"
                                strong="АКТУАЛЬНЫЕ ПРОБЛЕМЫ МЕДИЦИНСКОЙ ГЕНЕТИКИ ГЛАЗАМИ СТУДЕНТА - "
                                em="Ашимова М.Р., Рыспекова А.Э." other=" (31-35 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Бакиров-Н.Б.-Насиров-А.А.-Бабаев-А.Н.-Ибраимов-И.Х.-Бесимбаева-Л.Е.-АВТОМАТИЗИРОВАННАЯ-ИНФОРМАЦИОННАЯ-СИСТЕМА-ПО-УЧЕТУ-РАБОЧЕГО.pdf"
                                strong="АВТОМАТИЗИРОВАННАЯ ИНФОРМАЦИОННАЯ СИСТЕМА ПО УЧЕТУ РАБОЧЕГО ВРЕМЕНИ СОТРУДНИКОВ - "
                                em="Бакиров Н.Б., Насиров А.А., Бабаев А.Н., Ибраимов И.Х., Бесимбаева Л.Е."
                                other=" (36-40 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Батырова-Ы.М.-АНАЛИЗ-РЕЗУЛЬТАТОВ-КОНСТАТИРУЮЩЕГО-ПЕДАГОГИЧЕСКОГО-ЭКСПЕРИМЕНТА-ПО-ФОРМИРОВАНИЮ-ИНСТРУМЕНТАЛЬНЫХ-КОМПЕТЕНЦИЙ-СТУДЕНТОВ.pdf"
                                strong="АНАЛИЗ РЕЗУЛЬТАТОВ КОНСТАТИРУЮЩЕГО ПЕДАГОГИЧЕСКОГО ЭКСПЕРИМЕНТА ПО ФОРМИРОВАНИЮ ИНСТРУМЕНТАЛЬНЫХ КОМПЕТЕНЦИЙ СТУДЕНТОВ - "
                                em="Батырова Ы.М." other=" (41-47 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Бейшеналиева-А.Н.-Албанбаева-Дж.О.-ЯЗЫКОВЫЙ-ДРИФТ-В-РЕКЛАМНОМ-ДИСКУРСЕ.pdf"
                                strong="ЯЗЫКОВЫЙ ДРИФТ В РЕКЛАМНОМ ДИСКУРСЕ: НА МАТЕРИАЛЕ АНГЛИЙСКОГО И РУССКОГО ЯЗЫКА - "
                                em="Бейшеналиева А.Н., Албанбаева Дж.О." other=" (48-53 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Биймырсаева-А.К.-ЭКОНОМИЧЕСКАЯ-ЭФФЕКТИНОСТЬ-СЕЛЬСКОХОЗЯЙСТВЕННОГО-ПРОИЗВОДСТВА.pdf"
                                strong="ЭКОНОМИЧЕСКАЯ ЭФФЕКТИНОСТЬ СЕЛЬСКОХОЗЯЙСТВЕННОГО ПРОИЗВОДСТВА - "
                                em="Биймырсаева А.К." other=" (54-58 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Борбучалова-Б.Ж.-ИССЛЕДОВАНИЕ-ЖАНРОВ-САТИРЫ-И-ЮМОРА-ПОСЛЕ-ОБРЕТЕНИЯ-НЕЗАВИСИМОСТИ.pdf"
                                strong="ИССЛЕДОВАНИЕ ЖАНРОВ САТИРЫ И ЮМОРА ПОСЛЕ ОБРЕТЕНИЯ НЕЗАВИСИМОСТИ - "
                                em="Борбучалова Б.Ж." other=" (59-67 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Гапарова-Т.М.-НЕКОТОРЫЕ-ПУТИ-РАЗВИТИЯ-ИНТЕРЕСА-УЧАЩИХСЯ-К-ИЗУЧЕНИЮ-ИНОСТРАННОГО-ЯЗЫКА.pdf"
                                strong="НЕКОТОРЫЕ ПУТИ РАЗВИТИЯ ИНТЕРЕСА УЧАЩИХСЯ К ИЗУЧЕНИЮ ИНОСТРАННОГО ЯЗЫКА - "
                                em="Гапарова Т.М." other=" (68-73 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Джузбаева-А.Н.-ОСОБЕННОСТИ-ДЕКОРАТИВНО-ПРИКЛАДНОГО-ИСКУССТА-КЫРГЫЗСТАНА.pdf"
                                strong="ОСОБЕННОСТИ ДЕКОРАТИВНО-ПРИКЛАДНОГО ИСКУССТА КЫРГЫЗСТАНА - " em="Джузбаева А.Н."
                                other=" (74-79 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Долбин-К.С.-ЭФФЕКТИВНАЯ-АППРОКСИМАЦИЯ-ТОКОВ-НАПРЯЖЕНИЙ-И-МОЩНОСТЕЙ-ДЛЯ-ОПТИМИЗАЦИИ.pdf"
                                strong="ЭФФЕКТИВНАЯ АППРОКСИМАЦИЯ ТОКОВ, НАПРЯЖЕНИЙ И МОЩНОСТЕЙ ДЛЯ ОПТИМИЗАЦИИ - "
                                em="Долбин К.С." other="(81-81 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Дуйшеналиева-А.Дж.-Аттокурова-А.А.-Риферт-К.П.-Масирова-С.К.-Чжун-Юн-СОВРЕМЕННЫЕ-ПРОБЛЕМЫ-РЕАЛИЗАЦИИ-СУ.pdf"
                                strong="СОВРЕМЕННЫЕ ПРОБЛЕМЫ РЕАЛИЗАЦИИ СТРАТЕГИЧЕСКОГО УПРАВЛЕНИЯ - "
                                em="Дуйшеналиева А.Дж., Аттокурова А.А., Риферт К.П., Масирова С.К., Чжун Юн"
                                other=" (91-95 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Есенаманов-Н.К.-МОДЕЛИРОВАНИЕ-ПРОЦЕССА-ВОЗВЕДЕНИЯ-ВЫСОТНОГО-КАРКАСНО-МОНОЛИТНОГО-ЗДАНИЯ.pdf"
                                strong="МОДЕЛИРОВАНИЕ ПРОЦЕССА ВОЗВЕДЕНИЯ ВЫСОТНОГО КАРКАСНО-МОНОЛИТНОГО ЗДАНИЯ - "
                                em="Есенаманов Н.К." other=" (96-100 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Ефременкова-М.В.-Попинако-Я.В.-Кизилов-С.А.-Никитенко-М.С.-ИССЛЕДОВАНИЕ-ПРОГРАММНЫХ-СПОСОБОВ-КОРРЕКТИРОВКИ-ДЕФЕКТОВ.pdf"
                                strong="ИССЛЕДОВАНИЕ ПРОГРАММНЫХ СПОСОБОВ КОРРЕКТИРОВКИ ДЕФЕКТОВ - "
                                em="Ефременкова М.В., Попинако Я.В., Кизилов С.А., Никитенко М.С."
                                other=" (104-110 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Жаныбек-кызы-Д.-Исабеков-А.А.-Джумабекова-К.Б.-НЕ-ТРАДИЦИОННЫЕ-МЕТОДЫ-ПРЕПОДАВАНИЯ-.pdf"
                                strong="НЕ ТРАДИЦИОННЫЕ МЕТОДЫ ПРЕПОДАВАНИЯ В КЫРГЫЗСКОМ ИНСТИТУТЕ ЯЗЫКОВ И КУЛЬТУРЫ - "
                                em="Жаныбек кызы Д., Исабеков А.А., Джумабекова К.Б." other=" (111-116 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Жусупкелдиев-К.К.-ФОРМИРОВАНИЕ-КОММУНИКАТИВНОЙ-КОМПЕТЕНЦИИ-БУДУЩИХ-МЕНЕДЖЕРОВ-В-ПРОЦЕССЕ-ИЗУЧЕНИЯ-КЫРГЫЗСКОГО-ЯЗЫКА.pdf"
                                strong="ФОРМИРОВАНИЕ КОММУНИКАТИВНОЙ КОМПЕТЕНЦИИ БУДУЩИХ МЕНЕДЖЕРОВ В ПРОЦЕССЕ ИЗУЧЕНИЯ КЫРГЫЗСКОГО ЯЗЫКА - "
                                em="Жусупкелдиев К.К." other=" (117-121 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Зейнулина-А.Ж.-Амантурова-Д.К.-Габдуллина-Л.Б.-РАЗВИТИЕ-И-СОВЕРШЕНСТВОВАНИЕ-ЭФФЕКТИВНОЙ.pdf"
                                strong="РАЗВИТИЕ И СОВЕРШЕНСТВОВАНИЕ ЭФФЕКТИВНОЙ ТРАНСПОРТНО-ЛОГИСТИЧЕСКОЙ СИСТЕМЫ В РЕСПУБЛИКЕ КАЗАХСТАН - "
                                em="Зейнулина А.Ж., Амантурова Д.К., Габдуллина Л.Б." other=" (122-128 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Исаев-Р.Р.-Эсеналиев-Э.-РАЗРАБОТКА-СИСТЕМЫ-МОНИТОРИНГА-В-ОБРАЗОВАТЕЛЬНОМ-УЧРЕЖДЕНИИ.pdf"
                                strong="РАЗРАБОТКА СИСТЕМЫ МОНИТОРИНГА В ОБРАЗОВАТЕЛЬНОМ УЧРЕЖДЕНИИ: ПРОБЛЕМЫ И РЕШЕНИЯ - "
                                em="Исаев Р.Р., Эсеналиев Э." other=" (129-134 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Ишангали-А.Б.-Акмалайулы-К.-ЭФФЕКТИВНЫЕ-КОМПОЗИЦИОННЫЕ-МАТЕРИАЛЫ-НА-ОСНОВЕ-МИНЕРАЛЬНЫХ-ВЯЖУЩИХ.pdf"
                                strong="ЭФФЕКТИВНЫЕ КОМПОЗИЦИОННЫЕ МАТЕРИАЛЫ НА ОСНОВЕ МИНЕРАЛЬНЫХ ВЯЖУЩИХ - "
                                em="Ишангали А.Б., Акмалайулы К." other=" (135-141 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Камчыбек-уулу-М.-ИЗУЧЕНИЕ-САМОПОНИМАНИЯ-ЛИЧНОСТИ.pdf"
                                strong="ИЗУЧЕНИЕ САМОПОНИМАНИЯ ЛИЧНОСТИ В КОНТЕКСТЕ ГЛОБАЛИЗАЦИИ И ТРАНСФОРМАЦИИ ОБЩЕСТВА - "
                                em="Камчыбек уулу М." other=" (142-148 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Карыбаева-А.С.-ПРАВОВОЕ-РЕГУЛИРОВАНИЕ-БАНКОВСКОЙ-ДЕЯТЕЛЬНОСТИ.pdf"
                                strong="ПРАВОВОЕ РЕГУЛИРОВАНИЕ БАНКОВСКОЙ ДЕЯТЕЛЬНОСТИ - " em="Карыбаева А.С."
                                other=" (149-153 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Кененбаев-Э.-ФУНКЦИОНАЛЬНЫЕ-СООТНОШЕНИЯ-И-ДИНАМИЧЕСКОЕ-ПРЕДСТАВЛЕНИЕ-ОБЪЕКТОВ.pdf"
                                strong="ФУНКЦИОНАЛЬНЫЕ СООТНОШЕНИЯ И ДИНАМИЧЕСКОЕ ПРЕДСТАВЛЕНИЕ ОБЪЕКТОВ - "
                                em="Кененбаев Э." other=" (154-158 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Кокумова-А.Т.-РОЛЬ-ИСКУССТВЕННОГО-ИНТЕЛЛЕКТА-В-РАЗВИТИИ-ЭКОНОМИКИ.pdf"
                                strong="РОЛЬ ИСКУССТВЕННОГО ИНТЕЛЛЕКТА В РАЗВИТИИ ЭКОНОМИКИ - " em="Кокумова А.Т."
                                other=" (159-163 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Куркин-В.А.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-ПРОЦЕССОМ-ОБЕЗВОЖИВАНИЯ-ДИХЛОРЭТАНА.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ ПРОЦЕССОМ ОБЕЗВОЖИВАНИЯ ДИХЛОРЭТАНА"
                                em=" - Куркин В.А." other=" (163-166 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Кыдыралиев-Э.М.-ТЕХНОЛОГИЯ-ПОЛУЧЕНИЯ-УЛЬТРАДИСПЕРСНОЙ-ОГНЕУПОРНОЙ-КЕРАМИКИ.pdf"
                                strong="ТЕХНОЛОГИЯ ПОЛУЧЕНИЯ УЛЬТРАДИСПЕРСНОЙ ОГНЕУПОРНОЙ КЕРАМИКИ"
                                em=" - Кыдыралиев Э.М."
                                other=" (167-171 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Мамытов-С.М.-Сарбаева-Н.М.-ВЛИЯНИЕ-ХИМИЧЕСКИХ-ДОБАВОК-НА-СВОЙСТВА-БЕТОНА.pdf"
                                strong="ВЛИЯНИЕ ХИМИЧЕСКИХ ДОБАВОК НА СВОЙСТВА БЕТОНА"
                                em=" - Мамытов С.М., Сарбаева Н.М."
                                other=" (172-176 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Матыева-А.-К.-Эркинбек-к.-Г.-СРАВНИТЕЛЬНЫЙ-АНАЛИЗ-ПОВЕДЕНИЯ-РАМНОЙ-И-РАМНО-СВЯЗЕВОЙ-СИСТЕМЫ.pdf"
                                strong="СРАВНИТЕЛЬНЫЙ АНАЛИЗ ПОВЕДЕНИЯ РАМНОЙ И РАМНО-СВЯЗЕВОЙ СИСТЕМЫ ПО ПРОГРАММЕ SEISMOSTRUCT"
                                em=" - Матыева А. К., Эркинбек к. Г." other=" (177-186 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Мергенбаева-С.Р.-ПУТИ-ФОРМИРОВАНИЯ-УЧЕБНОЙ-МОТИВАЦИИ-НА-УРОКАХ-КЫРГЫЗСКОГО-ЯЗЫКА.pdf"
                                strong="ПУТИ ФОРМИРОВАНИЯ УЧЕБНОЙ МОТИВАЦИИ НА УРОКАХ КЫРГЫЗСКОГО ЯЗЫКА"
                                em=" - Мергенбаева С.Р." other=" (187-192 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Мергенбаева-С.Р.-Рыспаева-Ч.К.-ПОВЫШЕНИЕ-УЧЕБНОЙ-МОТИВАЦИИ-ПРИ-ИЗУЧЕНИИ-КЫРГЫЗСКОГО-ЯЗЫКА.pdf"
                                strong="ПОВЫШЕНИЕ УЧЕБНОЙ МОТИВАЦИИ ПРИ ИЗУЧЕНИИ КЫРГЫЗСКОГО ЯЗЫКА"
                                em=" - Мергенбаева С.Р., Рыспаева Ч.К." other=" (193-197 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Нуридин-З.-Турдукулов-Ф.З.-СОВРЕМЕННЫЕ-ПОДХОДЫ-И-КОНЦЕПЦИИ-В-ПРАКТИКЕ-УПРАВЛЕНИЯ.pdf"
                                strong="СОВРЕМЕННЫЕ ПОДХОДЫ И КОНЦЕПЦИИ В ПРАКТИКЕ УПРАВЛЕНИЯ"
                                em=" - Нуридин З., Турдукулов Ф.З." other=" (198-208 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Осмонова-Ж.Б.-АНАЛИЗ-И-ОЦЕНКА-ТЕКУЩЕЙ-СИТУАЦИИ-ПРОДОВОЛЬСТВЕННОЙ-БЕЗОПАСНОСТИ-В-КР.pdf"
                                strong="АНАЛИЗ И ОЦЕНКА ТЕКУЩЕЙ СИТУАЦИИ ПРОДОВОЛЬСТВЕННОЙ БЕЗОПАСНОСТИ В КР"
                                em=" - Осмонова Ж.Б." other=" (209-214 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Пацев-М.С-Эйсмонт-И.Т.-ОБРАЗОВАНИЕ-КАК-ФАКТОР-ПОВЫШЕНИЯ-КОНКУРЕНТОСПОСОБНОСТИ-СПЕЦИАЛИСТОВ.pdf"
                                strong="ОБРАЗОВАНИЕ КАК ФАКТОР ПОВЫШЕНИЯ КОНКУРЕНТОСПОСОБНОСТИ СПЕЦИАЛИСТОВ НА РЫНКЕ ТРУДА"
                                em=" - Пацев М.С, Эйсмонт И.Т." other=" (215-219 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Полухина-А.Н.-ИННОВАЦИОННЫЕ-ПОДХОДЫ-РАЗВИТИЯ-СФЕРЫ-ТУРИЗМА-В-РОССИИ-ИТОГИ-2022-Г..pdf"
                                strong="ИННОВАЦИОННЫЕ ПОДХОДЫ РАЗВИТИЯ СФЕРЫ ТУРИЗМА В РОССИИ (ИТОГИ 2022 г.)"
                                em=" - Полухина А.Н." other=" (220-227 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Садыков-А.М.-КЛЮЧЕВЫЕ-ФАКТОРЫ-УСПЕШНОЙ-РАЗРАБОТКИ-КОРПОРАТИВНОГО-САЙТА.pdf"
                                strong="КЛЮЧЕВЫЕ ФАКТОРЫ УСПЕШНОЙ РАЗРАБОТКИ КОРПОРАТИВНОГО САЙТА КАК ИНСТРУМЕНТА ДЛЯ УПРАВЛЕНИЯ БИЗНЕС-ПРОЕКТАМИ"
                                em=" - Садыков А.М." other=" (228-236 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Сартбекова-Н.К.-Ван-Жуй-ИСТОРИЯ-РАЗВИТИЯ-ТРАДИЦИОННОЙ-НАРОДНОЙ-МУЗЫКИ-КР.pdf"
                                strong="ИСТОРИЯ РАЗВИТИЯ ТРАДИЦИОННОЙ НАРОДНОЙ МУЗЫКИ КЫРГЫЗСТАНА"
                                em=" - Сартбекова Н.К., Ван Жуй" other=" (237-242 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Серик-А.Ж.-Султанбаева-Г.С.-Керимов-У.Т.-Шайнусов-Р.Т.-Ачекеев-К.С.-ПРОЕКТИРОВАНИЕ-МОДЕЛИ-ЭЛЕКТРОННОЙ-ПЛАТФОРМЫ-ОБУЧЕНИЯ.pdf"
                                strong="ПРОЕКТИРОВАНИЕ МОДЕЛИ ЭЛЕКТРОННОЙ ПЛАТФОРМЫ ОБУЧЕНИЯ УРОКАМ БИОЛОГИИ НА DJANGO ORM"
                                em=" - Серик А.Ж., Султанбаева Г.С., Керимов У.Т., Шайнусов Р.Т., Ачекеев К.С."
                                other=" (243-250 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Серяков-А.М.-ИЗУЧЕНИЕ-ПРИМЕНЕНИЯ-АДАПТИВНОГО-УПРАВЛЕНИЯ-В-ПРОЦЕССАХ-ОЧИСТКИ-НЕФТЕХИМ.pdf"
                                strong="ИЗУЧЕНИЕ ПРИМЕНЕНИЯ АДАПТИВНОГО УПРАВЛЕНИЯ В ПРОЦЕССАХ ОЧИСТКИ НЕФТЕХИМИЧЕСКИХ ПРОДУКТОВ ОТ ПРИМЕСЕЙ"
                                em=" - Серяков А.М." other=" (251-253 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Слюсарева-Е.А.-Затравина-С.В.-ТРАНСФОРМАЦИЯ-УЧЕТА-И-КОНТРОЛЯ-В-УСЛОВИЯХ-ЦИФРОВОЙ-ЭКОНОМИКИ.pdf"
                                strong="ТРАНСФОРМАЦИЯ УЧЕТА И КОНТРОЛЯ В УСЛОВИЯХ ЦИФРОВОЙ ЭКОНОМИКИ - "
                                em="Слюсарева Е.А., Затравина С.В." other=" (254-258 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Стефан-Д.-Нурумбетов-С.Б.-О-ЗНАЧЕНИИ-ИНТЕЛЛЕКТУАЛЬНОГО-КАПИТАЛА-В-УСЛОВИЯХ-РАЗВИТИЯ-СУБЬЕКТОВ-ЭКОНОМИКИ.pdf"
                                strong="О ЗНАЧЕНИИ ИНТЕЛЛЕКТУАЛЬНОГО КАПИТАЛА В УСЛОВИЯХ РАЗВИТИЯ СУБЪЕКТОВ ЭКОНОМИКИ - "
                                em="Стефан Д., Нурумбетов С.Б." other=" (259-263 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Суйналиева-Н.К.-Субанбекова-Ы.С.-РАЗВИТИЕ-ИННОВАЦИОННОЙ-ДЕЯТЕЛЬНОСТИ-КР.pdf"
                                strong="РАЗВИТИЕ ИННОВАЦИОННОЙ ДЕЯТЕЛЬНОСТИ КР - "
                                em="Суйналиева Н.К., Субанбекова Ы.С."
                                other=" (264-268 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Тагаева-С.Б.-АЛГОРИТМЫ-ДЛЯ-ОПРЕДЕЛЕНИЯ-СВОЙСТВ-РЕШЕНИЙ-НЕЛИНЕЙНЫХ-ОБЫКНОВЕННЫХ-ДИФФЕРЕНЦИАЛЬНЫХ-УРАВНЕНИЙ.pdf"
                                strong="АЛГОРИТМЫ ДЛЯ ОПРЕДЕЛЕНИЯ СВОЙСТВ РЕШЕНИЙ НЕЛИНЕЙНЫХ ОБЫКНОВЕННЫХ ДИФФЕРЕНЦИАЛЬНЫХ УРАВНЕНИЙ - "
                                em="Тагаева С.Б." other=" (269-274 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Тасболатова-Л.Т.-Орынбет-М.М.-МОДЕЛИРОВАНИЕ-И-АНАЛИЗ-СХЕМЫ-СОГЛАСОВАНИЯ-В-BTS-СИСТЕМЫ.pdf"
                                strong="МОДЕЛИРОВАНИЕ И АНАЛИЗ СХЕМЫ СОГЛАСОВАНИЯ В BTS СИСТЕМЫ - "
                                em="Тасболатова Л.Т., Орынбет М.М." other=" (275-282 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Ташмурзаева-М.Э.-ВЛИЯНИЕ-ИНФОРМАЦИОННЫХ-ТЕХНОЛОГИЙ-НА-МЕЖЛИЧНОСТНОЕ-ОБЩЕНИЕ-ПОДРОСТКОВ.pdf"
                                strong="ВЛИЯНИЕ ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ НА МЕЖЛИЧНОСТНОЕ ОБЩЕНИЕ ПОДРОСТКОВ - "
                                em="Ташмурзаева М.Э." other=" (283-286 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Тенгебаев-Н.Е.-Бесимбаева-Л.Е.-Шраздин-М.С.-Жанибеков-Д.Ж.-АДАПТИВНЫЕ-ТЕХНОЛОГИИ-В-СТРОИТЕЛЬСТВЕ.-ЮРТА.pdf"
                                strong="АДАПТИВНЫЕ ТЕХНОЛОГИИ В СТРОИТЕЛЬСТВЕ: ЮРТА ИЗ ПОЛИМЕРА НА 3D ПЕЧАТИ - "
                                em="Тенгебаев Н.Е., Бесимбаева Л.Е., Шраздин М.С., Жанибеков Д.Ж."
                                other=" (287-295 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Тинасилов-М.Д.-Уркумбаева-А.Р.-Тинасилов-Р.М.-ИННОВАЦИЯ-ОРГАНИЗАТОРСКОЙ-ДЕЯТЕЛЬНОСТИ-МЕНЕДЖЕРА-И-БИЗНЕСМЕНА.pdf"
                                strong="ИННОВАЦИЯ ОРГАНИЗАТОРСКОЙ ДЕЯТЕЛЬНОСТИ МЕНЕДЖЕРА И БИЗНЕСМЕНА - "
                                em="Тинасилов М.Д., Уркумбаева А.Р., Тинасилов Р.М." other=" (296-303 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Токмеилова-Н.-Акмалайулы-К.-ОПРЕДЕЛЕНИЕ-СОСТАВОВ-СТАЛЕФИБРОБЕТОНА.pdf"
                                strong="ОПРЕДЕЛЕНИЕ СОСТАВОВ СТАЛЕФИБРОБЕТОНА - " em="Токмеилова Н., Акмалайулы К."
                                other=" (304-311 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Тургунбекова-К.Т.-Акылбекова-Н.А.-Айдаркулова-Б.А.-РАЗВИТИЕ-ТАМОЖЕННОГО-КОНТРОЛЯ-В-КР.pdf"
                                strong="РАЗВИТИЕ ТАМОЖЕННОГО КОНТРОЛЯ В КЫРГЫЗСКОЙ РЕСПУБЛИКЕ - "
                                em="Тургунбекова К.Т., Акылбекова Н.А., Айдаркулова Б.А." other=" (312-317 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Уляева-Д.Д.-РАЗРАБОТКА-АВТОМАТИЗИРОВАННОЙ-СИСТЕМЫ-УПРАВЛЕНИЯ-КОТЕЛЬНЫМ-ОТДЕЛЕНИЕМ.pdf"
                                strong="РАЗРАБОТКА АВТОМАТИЗИРОВАННОЙ СИСТЕМЫ УПРАВЛЕНИЯ КОТЕЛЬНЫМ ОТДЕЛЕНИЕМ - "
                                em="Уляева Д.Д." other=" (318-321 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Хусаинова-Э.Ю.-СОСТОЯНИЕ-ФИНАНСОВОГО-УЧЕТА-В-КЫРГЫЗСТАНЕ.pdf"
                                strong="СОСТОЯНИЕ ФИНАНСОВОГО УЧЕТА В КЫРГЫЗСТАНЕ - " em="Хусаинова Э.Ю."
                                other=" (322-325 стр.)"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/ШShafiullah-Hutak-Толегенова-А.-Акмалайулы-К.-БЕТОН-ДИСПЕРСНО-АРМИРОВАННЫМ-СИНТЕТИЧЕСКИМ-ВОЛОКНОМ.pdf"
                                strong="БЕТОН ДИСПЕРСНО-АРМИРОВАННЫМ СИНТЕТИЧЕСКИМ ВОЛОКНОМ - "
                                em="Hutak Shafiullah, Толегенова А., Акмалайулы К." other=" (326-332 стр.)"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/Шулаева-Е.А.-Пенкин-И.А.-ПЕРСПЕКТИВЫ-ВНЕДРЕНИЯ-ЦИФРОВОГО-ДВОЙНИКА-ПРОИЗВОДСТВА.pdf"
                                strong="Шулаева Е.А., Пенкин И.А. - ПЕРСПЕКТИВЫ ВНЕДРЕНИЯ ЦИФРОВОГО ДВОЙНИКА ПРОИЗВОДСТВА"/>

                            <ArchiveLink
                                href="/articles/sit/2023/09/Ягафарова-Г.А.-Кириллов-К.О.-Могучев-И.В.-Усманов-И.С.-ЦЕЛЕСООБРАЗНОСТЬ-ПИРАМИДЫ-ЧЕЛОВЕЧЕСКИХ-ПОТРЕБНОСТЕЙ.pdf"
                                strong="Ягафарова Г.А., Кириллов К.О., Могучев И.В., Усманов И.С. - ЦЕЛЕСООБРАЗНОСТЬ ПИРАМИДЫ ЧЕЛОВЕЧЕСКИХ ПОТРЕБНОСТЕЙ"/>


                            <ArchiveLink
                                href="/articles/sit/2023/09/ЯИманкулова-Б.-Кушубакова-А.-Досматова-Б.-Югай-А.-Бегалиева-А.-Юбурова-С.И.-Апсаматов-Б.К.-ГОТОВНОСТЬ-ГОРОДА-К-ЧС.pdf"
                                strong="ЯИманкулова Б., Кушубакова А., Досматова Б., Югай А., Бегалиева А., Юбурова С.И., Апсаматов Б.К. - ГОТОВНОСТЬ ГОРОДА К ЧС"/>
                        </div>
                    }/>

                    {/*<CustomAccordion title={"Выпуск №25 (4кв. 2022г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №24 (3кв. 2022г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №23 (2кв. 2022г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №22 (1кв. 2022г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №21 (4кв. 2021г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №20 (3кв. 2021г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №19 (2кв. 2021г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №18 (1кв. 2021г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №17 (4кв. 2020г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №16 (3кв. 2020г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №15 (2кв. 2020г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №14 (1кв. 2020г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №13 (4кв. 2019г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №12 (3кв. 2019г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №11 (2кв. 2019г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №10 (1кв. 2019г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №9 (4кв. 2018г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №8 (3кв. 2018г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №7 (2кв. 2018г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №6 (1кв. 2018г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №5 (3кв. 2017г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №4 (2кв. 2017г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №3 (1кв. 2017г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №2 (2кв. 2016г.)"} content={}/>*/}
                    {/*<CustomAccordion title={"Выпуск №1 (1кв. 2016г.)"} content={}/>*/}

                    <CustomAccordion title={"Выпуск №25 (4кв. 2022г.)"} content={""


                    }/>
                    <CustomAccordion title={"Выпуск №24 (3кв. 2022г.)"} content={""

                    }/>
                    <CustomAccordion title={"Выпуск №23 (2кв. 2022г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №22 (1кв. 2022г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №21 (4кв. 2021г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №20 (3кв. 2021г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №19 (2кв. 2021г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №18 (1кв. 2021г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №17 (4кв. 2020г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №16 (3кв. 2020г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №15 (2кв. 2020г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №14 (1кв. 2020г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №13 (4кв. 2019г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №12 (3кв. 2019г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №11 (2кв. 2019г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №10 (1кв. 2019г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №9 (4кв. 2018г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №8 (3кв. 2018г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №7 (2кв. 2018г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №6 (1кв. 2018г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №5 (3кв. 2017г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №4 (2кв. 2017г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №3 (1кв. 2017г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №2 (2кв. 2016г.)"} content={""}/>
                    <CustomAccordion title={"Выпуск №1 (1кв. 2016г.)"} content={""}/>


                </Box>
            </Paper>
        </Container>
    );
};

export default TitleList;
