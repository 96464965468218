import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Rating} from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import config from "../../../Config";

export default function FormDialog({navigate, id}) {
    const [open, setOpen] = React.useState(false);

    const [AccessToken, setStoredToken] = useState("");

    useEffect(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (!storedToken || storedToken === "") {
            navigate("/Auth");
        } else {
            setStoredToken(storedToken);
        }
    }, [navigate]);

    const [review, setReview] = useState({
        post: id,
        review: "",
        rating: "",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setReview({...review, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!AccessToken || AccessToken === "") {
            navigate("/Auth");
        }

        setReview({...review, post: id});

        const postData = new FormData();
        const stateNames = Object.keys(review);

        // Пройти по всем именам useState и добавить их в formData
        for (const stateName of stateNames) {
            const stateValue = review[stateName];
            postData.append(stateName, stateValue);
        }

        axios.post(config.apiUrl + `/posts/${id}/comment/`, postData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${AccessToken}`,
            },
        })
            .then((response) => {
                // Обработка успешного ответа
                console.log(response);
                window.location.reload();
            })
            .catch((error) => {
                // Обработка ошибки
                alert(error);
            });

        console.log(review);

    };


    return (
        <React.Fragment>
            <Button size='small' variant='outlined' onClick={handleClickOpen}>
                Review
            </Button>
            <Dialog maxWidth={"lg"} open={open} onClose={handleClose}>
                <DialogTitle><Typography fontSize={20} fontStyle='normal' variant='button'>Write your Recent on this
                    article</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Describe your opinion of the current article. We recommend using only objective judgments and
                        evaluating the article, not the author.
                        It is also strongly recommended to read the rules of reviews otherwise your review will be
                        deleted.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Review text"
                        fullWidth
                        variant={'outlined'}
                        multiline
                        minRows={4}
                        name='review'
                        value={review.review}
                        onChange={handleChange}
                    />
                    <Typography color={'text.secondary'} variant='button'>Your rating for this
                        article:</Typography><br/>
                    <Rating onChange={handleChange} value={review.rating} name="rating" defaultValue={0}
                            precision={0.5}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}