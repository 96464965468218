import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GeneralInfo from './GeneralInfo';
import AuthorsForm from './AuthorsForm';
import Review from './Review';
import axios from "axios";
import config from "../../Config";
import UploadForm from "./UploadForm";


const steps = ['General info', 'About the Authors', 'Upload Title', 'Review your info'];

function getStepContent(step, post, handleChange, data, addCo, removeCo, updateCo, fileChange) {

    switch (step) {
        case 0:
            return <GeneralInfo post={post} handleChange={handleChange}/>;
        case 1:
            return <AuthorsForm post={post} handleChange={handleChange} data={data} addCoauthor={addCo}
                                removeCoauthor={removeCo} updateCoauthor={updateCo}/>;

        case 2:
            return <UploadForm fileChange={fileChange}/>
        case 3:
            return <Review post={post}/>;
        default:
            throw new Error('Unknown step');
    }
}

export default function AddTitle({navigate}) {
    const [AccessToken, setStoredToken] = useState("");

    useEffect(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (!storedToken || storedToken === "") {
            navigate("/Auth");
        } else {
            setStoredToken(storedToken);
        }
    }, [navigate]);

    const [post, setPost] = useState({
        title_ru: "",
        title_en: "",
        title_kg: "",

        title_author_en: "",
        author_organization_en: "",
        contact_email: "",

        co_authors_en: "",
        co_authors_organization_en: "",
        co_authors_emails: "",

        annotation_ru: "",
        annotation_en: "",
        annotation_kg: "",

        keywords_ru: "",
        keywords_en: "",
        keywords_kg: "",
    });

    const [postFile, setPostFile] = useState(null);

    const [coauthorsData, setCoauthors] = useState([
        {
            co_authors_en: "",
            co_authors_emails_en: "",
            co_authors_organization_en: "",
        },
    ]);

    const addCoauthor = () => {
        setCoauthors([
            ...coauthorsData,
            {
                co_authors_emails: "",
                co_authors_en: "",
                co_authors_organization_en: "",
            },
        ]);
    };

    const removeCoauthor = (index) => {
        setCoauthors(coauthorsData.filter((_, i) => i !== index));
    };

    const updateCoauthor = (index, field, value) => {
        setCoauthors((prevState) =>
            prevState.map((coauthor, i) =>
                i === index ? {...coauthor, [field]: value} : coauthor
            )
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!AccessToken || AccessToken === "") {
            navigate("/Auth");
        }

        const coauthorStringEn = coauthorsData
            .map((coauthor) => coauthor.co_authors_en)
            .join(";");

        const coauthorEmailString = coauthorsData
            .map((coauthor) => coauthor.co_authors_emails)
            .join(";");

        const coauthorOrgStringEn = coauthorsData
            .map((coauthor) => coauthor.co_authors_organization_en)
            .join(";");

        setPost({
            ...post,
            co_authors_en: coauthorStringEn,
            co_authors_emails: coauthorEmailString,
            co_authors_organization_en: coauthorOrgStringEn,

        });

        const postData = new FormData();

        // Получить все имена useState
        const stateNames = Object.keys(post);

        // Пройти по всем именам useState и добавить их в formData
        for (const stateName of stateNames) {
            const stateValue = post[stateName];
            postData.append(stateName, stateValue);
        }

        postData.append("file", postFile);

        axios.post(config.apiUrl + "/posts/post-load/", postData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${AccessToken}`,
            },
        })
            .then((response) => {
                // Обработка успешного ответа
                console.log(response);
            })
            .catch((error) => {
                // Обработка ошибки
                console.error(error);
            });

        console.log(post);

        navigate("/Archives")
    };

    const handleChange = (e) => {
        setPost({...post, [e.target.name]: e.target.value});
    };

    const handleFileChange = (e) => {
        console.log(e);
        setPostFile(e.target.files[0]);
    };

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    return (
        <form>
            <Container component="main" maxWidth="md" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography fontStyle='normal' component="h1" variant="h4" align="center">
                        Article Post
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your order.
                            </Typography>
                            <Typography variant="subtitle1">
                                Your order number is #2001539. We have emailed your order
                                confirmation, and will send you an update when your order has
                                shipped.
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {getStepContent(activeStep, post, handleChange, coauthorsData, addCoauthor,
                                removeCoauthor, updateCoauthor, handleFileChange)}
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                        Back
                                    </Button>
                                )}

                                {activeStep === steps.length - 1 ? (
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{mt: 3, ml: 1}}
                                    >
                                        Publish
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mt: 3, ml: 1}}
                                    >
                                        Next
                                    </Button>
                                )}

                            </Box>
                        </React.Fragment>
                    )}
                </Paper>

            </Container>
        </form>
    );
}