import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';


export default function GeneralInfo({post, handleChange}) {
    const languages = {'kg': 'Kyrgyz', 'en': 'English', 'ru': 'Russian'};

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                General Info
            </Typography>
            <Grid container spacing={3}>
                {Object.entries(languages).map(([langCode, languageName]) => (
                    <Grid key={langCode} item xs={12}>
                        <TextField
                            required
                            id={`title_${langCode}`}
                            name={`title_${langCode}`}
                            label={`Title (on ${languageName})`}
                            fullWidth
                            autoComplete={`title title-${langCode}`}
                            variant="standard"
                            value={post[`title_${langCode}`]}
                            onChange={handleChange}
                        />
                    </Grid>
                ))}

                {Object.entries(languages).map(([langCode, languageName]) => (
                    <Grid item key={langCode} xs={12}>
                        <Typography align={"center"} color={"#8c8c8c"} variant="subtitle2" gutterBottom>
                            On {`${languageName}`}
                        </Typography>
                        <TextField
                            required
                            id={`annotation_${langCode}`}
                            name={`annotation_${langCode}`}
                            label="Abstract (250-500 words)"
                            fullWidth
                            autoComplete={`annotation annotation-${langCode}`}
                            variant="outlined"
                            multiline
                            minRows={8}
                            value={post[`annotation_${langCode}`]}
                            onChange={handleChange}
                        />

                        <Grid item xs={12} marginTop={"10px"}>
                            <TextField
                                required
                                id={`keywords_${langCode}`}
                                name={`keywords_${langCode}`}
                                label="Keywords (at least 5)"
                                fullWidth
                                autoComplete={`keywords keywords_${langCode}`}
                                variant="outlined"
                                multiline
                                minRows={4}
                                value={post[`keywords_${langCode}`]}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                ))}

            </Grid>
        </React.Fragment>
    );
}
