import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Input} from "@mui/material";


export default function UploadForm({fileChange}) {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Upload File
            </Typography>
            <Grid container spacing={3}>

                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Input type="file" id="file" onChange={fileChange} name="file" style={{background: 'none'}}/>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
