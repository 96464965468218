//React
import React, {useState} from 'react';

//Packages
import axios from 'axios';

//Local
import classes from "./AuthStyle.module.css";
import {saveDataToSessionStorage} from "./Utils/saveDataToSessionStorage";

//Config
import config from "../../Config";
import {Alert, Backdrop, Box, CircularProgress, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

function RegistrationForm({handleLogIn, setAlertMessage, handleAlertToggle}) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [academic, setAcademic] = useState('');

    const [open, setOpen] = useState(false);
    const [alertMessageError, setAlertMessageError] = useState("Возникла ошибка. Попробуйте еще раз")

    const [verify, setVerify] = useState('')
    const [verifyCode, setVerifyCode] = useState('')

    const [openDialog, setOpenDialog] = useState(false);
    const [backDrop, setBackDrop] = useState(false)
    const handleClickOpen = () => {
        setOpenDialog(true);
    };


    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleDialog = (event) => {
        event.preventDefault()
        if (`${verify}` !== verifyCode) {
            alert('Проверьте правильность кода')
        } else {
            handleBackDrop();
            setAlertMessage("Вы успешно авторизовались на сайте, ознакомьтесь с главной страницей и документацией перед тем как публиковать статьи");
            handleAlertToggle();
            handleLogIn();
        }


    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleBackDrop();

        // Отправляем данные на API
        const userData = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            user_organization: organization,
            password: password,
            academic: academic
        };

        axios.post(config.apiUrl + '/users/register/', userData)
            .then(response => {
                saveDataToSessionStorage(response.data);
                setVerify(response.data.verify)
                console.log(response.data)
                handleClickOpen();
            })
            .catch(error => {
                console.error(error);
                handleBackDrop();
                setOpen(true)
            });
    };

    const handleBackDrop = () => {
        setBackDrop(!backDrop);
    }

    return (
        <div>
            <>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backDrop}
                    onClick={handleBackDrop}
                >
                    <CircularProgress color="success" />
                </Backdrop>


                <Box sx={{width: '100%'}}>
                    <Collapse in={open}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                            sx={{mb: 2}}
                        >
                            {alertMessageError}
                        </Alert>
                    </Collapse>
                </Box>
            </>
            <div className={classes.container}>

                <form className={classes.form_auth} onSubmit={handleSubmit}>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='First Name' type="text" value={firstName}
                               onChange={(e) => setFirstName(e.target.value)}/>
                    </label>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Last Name' type="text" value={lastName}
                               onChange={(e) => setLastName(e.target.value)}/>
                    </label>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Email' type="email" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                    </label>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Organization' type="text"
                               value={organization} onChange={(e) => setOrganization(e.target.value)}/>
                    </label>
                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Academic Degree' type="text"
                               value={academic} onChange={(e) => setAcademic(e.target.value)}/>
                    </label>

                    <label className={classes.label_auth}>
                        <input className={classes.input_auth} placeholder='Password' type="password" value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                    </label>
                    <button className={classes.button_auth} type="submit">Sign Up</button>
                </form>
            </div>

            <Dialog maxWidth={"lg"} open={openDialog} onClose={handleClose}>
                <DialogTitle><Typography fontSize={20} fontStyle='normal' variant='button'>Enter your verification
                    code</Typography></DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Verification code"
                        fullWidth
                        variant={'outlined'}
                        name='review'
                        value={verifyCode}
                        onChange={(e) => {
                            setVerifyCode(e.target.value)
                        }}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDialog}>Send</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default RegistrationForm;
