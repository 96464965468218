import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {Box, Button} from "@mui/material";

export default function AuthorsForm({post, handleChange, data, removeCoauthor, addCoauthor, updateCoauthor}) {
    const last_index = data.length - 1;

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                About the Author
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="title_author_en"
                        name="title_author_en"
                        label="Author First Name and Last Name"
                        fullWidth
                        onChange={handleChange}
                        autoComplete="title-author"
                        variant="standard"
                        value={post.title_author_en}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="contact_email"
                        name="contact_email"
                        label="Contact Mail"
                        fullWidth
                        onChange={handleChange}
                        autoComplete="email contact-email"
                        variant="standard"
                        value={post.contact_email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="author_organization_en"
                        name="author_organization_en"
                        label="Author Organization & Position"
                        fullWidth
                        onChange={handleChange}
                        autoComplete="organization"
                        variant="standard"
                        value={post.author_organization_en}
                    />
                </Grid>


                {data.map((coauthor, index) => (
                    <Grid item xs={12} key={index}>
                        <Typography variant="h6" gutterBottom>
                            Co-Author №{index + 1}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="co_authors_en"
                                    label="Co-Author First Name and Last Name"
                                    fullWidth
                                    autoComplete="name"
                                    variant="standard"
                                    onChange={(e) =>
                                        updateCoauthor(index, "co_authors_en", e.target.value)
                                    }
                                    value={coauthor.co_authors_en}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="co_authors_emails"
                                    label="Contact Mail"
                                    fullWidth
                                    autoComplete="email"
                                    variant="standard"
                                    onChange={(e) =>
                                        updateCoauthor(index, "co_authors_emails", e.target.value)
                                    }
                                    value={coauthor.co_authors_emails}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="co_authors_organization_en"
                                    label="Co-Author Organization & Position"
                                    fullWidth
                                    autoComplete="organization}"
                                    variant="standard"
                                    onChange={(e) =>
                                        updateCoauthor(
                                            index,
                                            "co_authors_organization_en",
                                            e.target.value
                                        )
                                    }
                                    value={coauthor.co_authors_organization_en}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" marginTop={"10px"}>
                            {index >= 1 && index === last_index && (
                                <Button
                                    onClick={() => removeCoauthor(index)}
                                    variant="contained"
                                    sx={{marginRight: 1}}
                                >
                                    Remove
                                </Button>
                            )}

                            {index === last_index && (
                                <Button onClick={addCoauthor} variant="contained">
                                    Add co-author
                                </Button>
                            )}

                        </Box>
                    </Grid>
                ))}

            </Grid>
        </React.Fragment>
    );
}