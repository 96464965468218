import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axios from "axios";
import config from "../Config";
import ArchiveItems from "./TitleList/ArchiveList/ArchiveItems";
import CustomAccordion from "./UI/accordion/Accordion";

const UserPage = ({navigate, firstName, lastName, organization, email}) => {

    const [AccessToken, setStoredToken] = useState("");

    const [posts, setPosts] = useState([]);
    const fetchPosts = async (token) => {
        try {
            const response = await axios.get(config.apiUrl + '/posts/post-load/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setPosts(response.data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (!storedToken || storedToken === "") {
            navigate("/Auth");
        } else {
            setStoredToken(storedToken);
        }

        fetchPosts(storedToken);

    }, [navigate]);


    return (
        <Container component="main" maxWidth="lg" sx={{mb: 4}}>

            <Paper style={{backgroundColor: "#f0f0f0"}} className={'blockText_docs'} elevation={7} variant="outlined"
                   sx={{my: {xs: 3, md: 6}}}>
                <Box className={'text_docs'}>
                    <Typography variant='button' fontStyle='normal' fontSize='22px' textAlign='center'>Publicist Profile
                        :</Typography>
                    <Grid container spacing={0} padding={0}>
                        <Grid item xs={12} sx={{marginLeft: 5}}>
                            <Typography fontSize="20px" variant='button' fontStyle='normal' fontWeight='500'>
                                {<Typography fontSize="20px" color='text.secondary' variant='button' fontStyle='normal'>Full
                                    Name:</Typography>} {firstName} {lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{marginLeft: 5}}>
                            <Typography color='text.secondary' variant='button' fontStyle='normal'>
                                Email: {<Link to={'mailto:' + email}>{email}</Link>}
                            </Typography>
                            <br/>
                            <Typography color='text.secondary' variant='button' fontStyle='normal'>
                                Organization: {<Typography variant='button' fontStyle='normal'
                                                           color='#0051b5'>{organization}</Typography>}
                            </Typography>
                        </Grid>

                        {/*<Typography marginTop={0} variant='button' fontStyle='normal' fontSize='22px'*/}
                        {/*            textAlign='center'>*/}
                        {/*    BIO :*/}
                        {/*</Typography>*/}
                        {/*<Grid item xs={12} >*/}
                        {/*    <TextField*/}
                        {/*        id={`annotation_`}*/}
                        {/*        name={`annotation_`}*/}
                        {/*        label="Write your BIO"*/}
                        {/*        fullWidth*/}
                        {/*        autoComplete={`annotation annotation-`}*/}
                        {/*        color="success"*/}
                        {/*        variant={'outlined'}*/}
                        {/*        multiline*/}
                        {/*        minRows={4}*/}
                        {/*    />*/}
                        {/*    <Box display="flex" justifyContent="right" marginRight={2} marginTop={1}>*/}
                        {/*        <Button variant="contained">*/}
                        {/*            Save*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}
                        <Typography marginTop={3} variant='button' fontStyle='normal' fontSize='22px'
                                    textAlign='center'>Titles :</Typography>
                        <Grid item xs={12}>
                            <Box fontStyle='normal'>
                                <CustomAccordion
                                    mode='true'
                                    title={
                                        <Box style={{width: '100%'}}>
                                            <Typography sx={{width: '100%', flexShrink: 1}} variant='button'>My
                                                titles</Typography>
                                            <Typography fontSize={14} display={'inline'}
                                                        color='text.secondary'> (count: {posts.length})</Typography>
                                        </Box>
                                    }
                                    content={
                                        posts
                                            .filter((post) => !post["is_published"])
                                            .length > 0 ? (
                                            posts
                                                .filter((post) => !post["is_published"])
                                                .map((post, index) => (
                                                    <Box key={post.id}>
                                                        <ArchiveItems number={index + 1} post={post}/>
                                                    </Box>
                                                ))
                                        ) : (
                                            <Box>
                                                <Typography
                                                    textAlign='center'
                                                    fontFamily='"Helvetica",sans-serif'
                                                    textTransform='uppercase'
                                                    letterSpacing='0.02857em'

                                                >Sorry, but no titles yet</Typography>
                                                <Typography
                                                    textAlign='center'
                                                    fontFamily='"Helvetica",sans-serif'
                                                    textTransform='uppercase'
                                                    letterSpacing='0.02857em'


                                                >Let's go to {<Link to={'/Submit'}>link</Link>} and publish your first
                                                    article!</Typography>
                                            </Box>
                                        )
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} marginTop={1}/>
                        <Grid item xs={12}>
                            <Box fontStyle='normal'>
                                <CustomAccordion
                                    mode='true'
                                    title={
                                        <Box style={{width: '100%'}}>
                                            <Typography sx={{width: '100%', flexShrink: 1}} variant='button'>My
                                                published
                                                titles</Typography>
                                            <Typography fontSize={14} display={'inline'}
                                                        color='text.secondary'> (count: {posts.filter((post) => post["is_published"]).length})</Typography>
                                        </Box>
                                    }
                                    content={
                                        posts
                                            .filter((post) => post["is_published"])
                                            .length > 0 ? (
                                            posts
                                                .filter((post) => post["is_published"])
                                                .map((post, index) => (
                                                    <Box key={post.id}>
                                                        <ArchiveItems number={index + 1} post={post}/>
                                                    </Box>
                                                ))
                                        ) : (
                                            <Typography
                                                textAlign='center'
                                                fontFamily='"Helvetica",sans-serif'
                                                textTransform='uppercase'
                                                letterSpacing='0.02857em'

                                            >Sorry, but no titles status is published yet {<br/>} Wait for your titles
                                                to be
                                                reviewed {<br/>} And good luck!</Typography>
                                        )
                                    }
                                />
                            </Box>

                        </Grid>
                        {/*<Typography marginTop={3} variant='button' fontStyle='normal' fontSize='22px'*/}
                        {/*            textAlign='center'>Settings*/}
                        {/*    :</Typography>*/}

                    </Grid>

                </Box>
            </Paper>


        </Container>

    );
};

export default UserPage;